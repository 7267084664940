import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { BlogComponent } from './blog/blog.component';
import { ContactusComponent } from './contactus/contactus.component';
import { PublicRFQComponent } from './public_rfq/public_rfq.component';
import { CareerComponent } from './career/career.component';
import { FaqComponent } from './faq/faq.component';
import { ForgotpassComponent } from './forgotpass/forgotpass.component';
import { HelpComponent } from './help/help.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ForbuyerComponent } from './forbuyer/forbuyer.component';
import { ForvendorComponent } from './forvendor/forvendor.component';
import { CustomfgComponent } from './blog/customfg/customfg.component';
import { VdsComponent } from './blog/vds/vds.component';
import { CofounderComponent } from './blog/cofounder/cofounder.component';
import { SupplychainComponent } from './blog/supplychain/supplychain.component';
import { Budget2021Component } from './blog/budget2021/budget2021.component';
import { GyvbComponent } from './blog/gyvb/gyvb.component';
import { BehindsComponent } from './blog/behinds/behinds.component';
import { LandingpagesComponent } from './home/landingpages/landingpages.component';
import { ManufacturingComponent } from './blog/manufacturing/manufacturing.component';
import { TestimonialsComponent } from './home/testimonials/testimonials.component';
import { StrategicpartnerComponent } from './home/strategicpartner/strategicpartner.component';
import { SiddharthengineeringComponent } from './vendors/siddharthengineering/siddharthengineering.component';
import { AadityaIndustriesComponent } from './vendors/aaditya-industries/aaditya-industries.component';
import { AadveekEnterprisesComponent } from './vendors/aadveek-enterprises/aadveek-enterprises.component';
import { AboAutocomComponent } from './vendors/abo-autocom/abo-autocom.component';
import { AccusionEngineeringPvtLtdComponent } from './vendors/accusion-engineering-pvt-ltd/accusion-engineering-pvt-ltd.component';
import { AccutechLaserPvtLtdComponent } from './vendors/accutech-laser-pvt-ltd/accutech-laser-pvt-ltd.component';
import { AdityaAutosComponent } from './vendors/aditya-autos/aditya-autos.component';
import { AimPrecisionIndustriesPvtLtdComponent } from './vendors/aim-precision-industries-pvt-ltd/aim-precision-industries-pvt-ltd.component';
import { AirgripIndustriesComponent } from './vendors/airgrip-industries/airgrip-industries.component';
import { AlmondEngineersComponent } from './vendors/almond-engineers/almond-engineers.component';
import { AludycoMfgCoComponent } from './vendors/aludyco-mfg-co/aludyco-mfg-co.component';
import { AluminaIndiaCastingCompanyComponent } from './vendors/alumina-india-casting-company/alumina-india-casting-company.component';
import { ApsTechEnterprisesComponent } from './vendors/aps-tech-enterprises/aps-tech-enterprises.component';
import { AsianMachineToolCorporationPvtLtdComponent } from './vendors/asian-machine-tool-corporation-pvt-ltd/asian-machine-tool-corporation-pvt-ltd.component';
import { AutoInternationalComponent } from './vendors/auto-international/auto-international.component';
import { AutomaticEngineeringWorksComponent } from './vendors/automatic-engineering-works/automatic-engineering-works.component';
import { AvnEnterprisesComponent } from './vendors/avn-enterprises/avn-enterprises.component';
import { BeejayIndustriesPvtLtdComponent } from './vendors/beejay-industries-pvt-ltd/beejay-industries-pvt-ltd.component';
import { BhorukaFabconsPvtLtdComponent } from './vendors/bhoruka-fabcons-pvt-ltd/bhoruka-fabcons-pvt-ltd.component';
import { BokharaIndustriesPvtLtdComponent } from './vendors/bokhara-industries-pvt-ltd/bokhara-industries-pvt-ltd.component';
import { ContactBearingCoPvtLtdComponent } from './vendors/contact-bearing-co-pvt-ltd/contact-bearing-co-pvt-ltd.component';
import { CreativeEngineersComponent } from './vendors/creative-engineers/creative-engineers.component';
import { CreativeToolsAndComponentsComponent } from './vendors/creative-tools-and-components/creative-tools-and-components.component';
import { DattaguruIndustriesPvtLtdComponent } from './vendors/dattaguru-industries-pvt-ltd/dattaguru-industries-pvt-ltd.component';
import { DcruzGearsIndiaPvtLtdComponent } from './vendors/dcruz-gears-india-pvt-ltd/dcruz-gears-india-pvt-ltd.component';
import { DeccanIndustriesComponent } from './vendors/deccan-industries/deccan-industries.component';
import { DeltaPiEngineeringComponent } from './vendors/delta-pi-engineering/delta-pi-engineering.component';
import { DimensionEnterpriseComponent } from './vendors/dimension-enterprise/dimension-enterprise.component';
import { DrastikEngineeringCompanyComponent } from './vendors/drastik-engineering-company/drastik-engineering-company.component';
import { DsFoundryComponent } from './vendors/ds-foundry/ds-foundry.component';
import { DurableMetalcraftComponent } from './vendors/durable-metalcraft/durable-metalcraft.component';
import { EfficncEngineeringLlpComponent } from './vendors/efficnc-engineering-llp/efficnc-engineering-llp.component';
import { EngeetechEngineeringsComponent } from './vendors/engeetech-engineerings/engeetech-engineerings.component';
import { EssaeGearsAndTransmissionsPvtLtdComponent } from './vendors/essae-gears-and-transmissions-pvt-ltd/essae-gears-and-transmissions-pvt-ltd.component';
import { ExtensiveManufacturingSolutionsComponent } from './vendors/extensive-manufacturing-solutions/extensive-manufacturing-solutions.component';
import { FixYantraEngineeringComponent } from './vendors/fix-yantra-engineering/fix-yantra-engineering.component';
import { GaneshIndustriesComponent } from './vendors/ganesh-industries/ganesh-industries.component';
import { GembaMetalFormingPvtLtdComponent } from './vendors/gemba-metal-forming-pvt-ltd/gemba-metal-forming-pvt-ltd.component';
import { GhanshyamMachiningComponent } from './vendors/ghanshyam-machining/ghanshyam-machining.component';
import { GunjalsIndustriesComponent } from './vendors/gunjals-industries/gunjals-industries.component';
import { HarEngineeringTradersComponent } from './vendors/har-engineering-traders/har-engineering-traders.component';
import { HexagonIndurstriesComponent } from './vendors/hexagon-indurstries/hexagon-indurstries.component';
import { HiTechEnterprisesComponent } from './vendors/hi-tech-enterprises/hi-tech-enterprises.component';
import { HorizonSteelsAndEngineeringComponent } from './vendors/horizon-steels-and-engineering/horizon-steels-and-engineering.component';
import { HynixTechnologiesComponent } from './vendors/hynix-technologies/hynix-technologies.component';
import { IFabEngineeringPvtLtdComponent } from './vendors/i-fab-engineering-pvt-ltd/i-fab-engineering-pvt-ltd.component';
import { InnovativeIndustriesComponent } from './vendors/innovative-industries/innovative-industries.component';
import { InnovicTechnologyComponent } from './vendors/innovic-technology/innovic-technology.component';
import { JagdishPrecisionComponent } from './vendors/jagdish-precision/jagdish-precision.component';
import { JyothiEngineeringWorksComponent } from './vendors/jyothi-engineering-works/jyothi-engineering-works.component';
import { KalariyaIndustriesComponent } from './vendors/kalariya-industries/kalariya-industries.component';
import { KalpakPrecisionIndustriesComponent } from './vendors/kalpak-precision-industries/kalpak-precision-industries.component';
import { KedarPrecisionComponentsComponent } from './vendors/kedar-precision-components/kedar-precision-components.component';
import { KepioEngineeringServicesPvtLtdComponent } from './vendors/kepio-engineering-services-pvt-ltd/kepio-engineering-services-pvt-ltd.component';
import { KhavareEngineeringComponent } from './vendors/khavare-engineering/khavare-engineering.component';
import { KhkIndustriesComponent } from './vendors/khk-industries/khk-industries.component';
import { KirdakAutocomPvtLtdComponent } from './vendors/kirdak-autocom-pvt-ltd/kirdak-autocom-pvt-ltd.component';
import { KrupaEngineeringComponent } from './vendors/krupa-engineering/krupa-engineering.component';
import { KuberEnterprisesComponent } from './vendors/kuber-enterprises/kuber-enterprises.component';
import { MahavirEngineeringComponent } from './vendors/mahavir-engineering/mahavir-engineering.component';
import { MarshPrecisionEngineeringComponent } from './vendors/marsh-precision-engineering/marsh-precision-engineering.component';
import { MaxwellAutoComponentsPvtLtdComponent } from './vendors/maxwell-auto-components-pvt-ltd/maxwell-auto-components-pvt-ltd.component';
import { MeedaasProtekPvtLtdComponent } from './vendors/meedaas-protek-pvt-ltd/meedaas-protek-pvt-ltd.component';
import { MetallsInternationalComponent } from './vendors/metalls-international/metalls-international.component';
import { MicrotechPrecisionComponent } from './vendors/microtech-precision/microtech-precision.component';
import { MmTechnologiesComponent } from './vendors/mm-technologies/mm-technologies.component';
import { MultiTechnologyComponent } from './vendors/multi-technology/multi-technology.component';
import { NikashCncEngineersPvtLtdComponent } from './vendors/nikash-cnc-engineers-pvt-ltd/nikash-cnc-engineers-pvt-ltd.component';
import { NirmalaEngineeringComponent } from './vendors/nirmala-engineering/nirmala-engineering.component';
import { OmkarEnterprisesComponent } from './vendors/omkar-enterprises/omkar-enterprises.component';
import { OmkarIndustriesComponent } from './vendors/omkar-industries/omkar-industries.component';
import { OmKartikComponent } from './vendors/om-kartik/om-kartik.component';
import { OviyanCastNForgePvtLtdComponent } from './vendors/oviyan-cast-n-forge-pvt-ltd/oviyan-cast-n-forge-pvt-ltd.component';
import { PAndPEnterprisesComponent } from './vendors/p-and-p-enterprises/p-and-p-enterprises.component';
import { ParagMetalsComponent } from './vendors/parag-metals/parag-metals.component';
import { ParishramEngineersComponent } from './vendors/parishram-engineers/parishram-engineers.component';
import { ParthManufacturingPvtLtdComponent } from './vendors/parth-manufacturing-pvt-ltd/parth-manufacturing-pvt-ltd.component';
import { PatelBrassTurnomaticsPvtLtdComponent } from './vendors/patel-brass-turnomatics-pvt-ltd/patel-brass-turnomatics-pvt-ltd.component';
import { PentaMetalSolutionsComponent } from './vendors/penta-metal-solutions/penta-metal-solutions.component';
import { PerfectToolsAndGaugesComponent } from './vendors/perfect-tools-and-gauges/perfect-tools-and-gauges.component';
import { PiIndustriesComponent } from './vendors/pi-industries/pi-industries.component';
import { PoojaEngineeringWorksComponent } from './vendors/pooja-engineering-works/pooja-engineering-works.component';
import { PoonaPressingsPvtLtdComponent } from './vendors/poona-pressings-pvt-ltd/poona-pressings-pvt-ltd.component';
import { PowerControlElectroSystemsPvtLtdComponent } from './vendors/power-control-electro-systems-pvt-ltd/power-control-electro-systems-pvt-ltd.component';
import { PraneelIndustriesComponent } from './vendors/praneel-industries/praneel-industries.component';
import { PrasannaengineersComponent } from './vendors/prasannaengineers/prasannaengineers.component';
import { PrecimetalWorksComponent } from './vendors/precimetal-works/precimetal-works.component';
import { PrecisionIndustriesComponent } from './vendors/precision-industries/precision-industries.component';
import { PrecitechForgingsAndEngineeringPvtLtdComponent } from './vendors/precitech-forgings-and-engineering-pvt-ltd/precitech-forgings-and-engineering-pvt-ltd.component';
import { PressMetalIndustriesComponent } from './vendors/press-metal-industries/press-metal-industries.component';
import { PujaFluidSealsPvtLtdComponent } from './vendors/puja-fluid-seals-pvt-ltd/puja-fluid-seals-pvt-ltd.component';
import { PushpakEngineeringIndiaPvtLtdComponent } from './vendors/pushpak-engineering-india-pvt-ltd/pushpak-engineering-india-pvt-ltd.component';
import { QualityEngineeringCoComponent } from './vendors/quality-engineering-co/quality-engineering-co.component';
import { RaghavForgePvtLtdComponent } from './vendors/raghav-forge-pvt-ltd/raghav-forge-pvt-ltd.component';
import { RaksAeroengineeringComponent } from './vendors/raks-aeroengineering/raks-aeroengineering.component';
import { RameshwarEnterprisesComponent } from './vendors/rameshwar-enterprises/rameshwar-enterprises.component';
import { RamjiSpringsComponent } from './vendors/ramji-springs/ramji-springs.component';
import { RangePreciseComponent } from './vendors/range-precise/range-precise.component';
import { RawgoldMetalComponent } from './vendors/rawgold-metal/rawgold-metal.component';
import { ReflectAdditiveComponent } from './vendors/reflect-additive/reflect-additive.component';
import { RixonFastenersPvtLtdComponent } from './vendors/rixon-fasteners-pvt-ltd/rixon-fasteners-pvt-ltd.component';
import { RobustPreciseEngineeringComponent } from './vendors/robust-precise-engineering/robust-precise-engineering.component';
import { RohitGroupOfIndustriesComponent } from './vendors/rohit-group-of-industries/rohit-group-of-industries.component';
import { RoyalEngineeringAndSolutionComponent } from './vendors/royal-engineering-and-solution/royal-engineering-and-solution.component';
import { RrIndustriesComponent } from './vendors/rr-industries/rr-industries.component';
import { SadhuForgingLtdComponent } from './vendors/sadhu-forging-ltd/sadhu-forging-ltd.component';
import { SagarIndustriesComponent } from './vendors/sagar-industries/sagar-industries.component';
import { SaienterprisesComponent } from './vendors/saienterprises/saienterprises.component';
import { SaiEnterprisesComponent } from './vendors/sai-enterprises/sai-enterprises.component';
import { SanjayTechnoProductsPvtLtdComponent } from './vendors/sanjay-techno-products-pvt-ltd/sanjay-techno-products-pvt-ltd.component';
import { SapEngineersComponent } from './vendors/sap-engineers/sap-engineers.component';
import { SarvodayEngineeringComponent } from './vendors/sarvoday-engineering/sarvoday-engineering.component';
import { SasaEnggtechPvtLtdComponent } from './vendors/sasa-enggtech-pvt-ltd/sasa-enggtech-pvt-ltd.component';
import { SavitriEnterprisesComponent } from './vendors/savitri-enterprises/savitri-enterprises.component';
import { ScandaIndustriesComponent } from './vendors/scanda-industries/scanda-industries.component';
import { ShakalyaEnterprisesComponent } from './vendors/shakalya-enterprises/shakalya-enterprises.component';
import { ShantdeepMetalsPvtLtdComponent } from './vendors/shantdeep-metals-pvt-ltd/shantdeep-metals-pvt-ltd.component';
import { SharanyapolymerComponent } from './vendors/sharanyapolymer/sharanyapolymer.component';
import { ShelarEngitechPvtLtdComponent } from './vendors/shelar-engitech-pvt-ltd/shelar-engitech-pvt-ltd.component';
import { ShivanandPressingComponentsComponent } from './vendors/shivanand-pressing-components/shivanand-pressing-components.component';
import { ShivSparesComponent } from './vendors/shiv-spares/shiv-spares.component';
import { ShreeChamundaKrupaIndustryComponent } from './vendors/shree-chamunda-krupa-industry/shree-chamunda-krupa-industry.component';
import { ShreeSaiIndustriesComponent } from './vendors/shree-sai-industries/shree-sai-industries.component';
import { ShreeSwamiSamarthEngineeringComponent } from './vendors/shree-swami-samarth-engineering/shree-swami-samarth-engineering.component';
import { ShriDattaIndustriesComponent } from './vendors/shri-datta-industries/shri-datta-industries.component';
import { ShriEngineersComponent } from './vendors/shri-engineers/shri-engineers.component';
import { ShriniwasMachineCraftPvtLtdComponent } from './vendors/shriniwas-machine-craft-pvt-ltd/shriniwas-machine-craft-pvt-ltd.component';
import { ShrivinTechnologiesLlpComponent } from './vendors/shrivin-technologies-llp/shrivin-technologies-llp.component';
import { ShrushtiIndustriesComponent } from './vendors/shrushti-industries/shrushti-industries.component';
import { ShubhneelIndustriesComponent } from './vendors/shubhneel-industries/shubhneel-industries.component';
import { SkTechnologiesComponent } from './vendors/sk-technologies/sk-technologys.component';
import { SolariaTechnologyPvtLtdComponent } from './vendors/solaria-technology-pvt-ltd/solaria-technology-pvt-ltd.component';
import { SomeshForgePvtLtdComponent } from './vendors/somesh-forge-pvt-ltd/somesh-forge-pvt-ltd.component';
import { SonaIndustriesComponent } from './vendors/sona-industries/sona-industries.component';
import { SpeedCraftIndustriesComponent } from './vendors/speed-craft-industries/speed-craft-industries.component';
import { SquareEngineeringComponent } from './vendors/square-engineering/square-engineering.component';
import { SrdcorpIndiaPvtLtdComponent } from './vendors/srdcorp-india-pvt-ltd/srdcorp-india-pvt-ltd.component';
import { SriVeluEnterprisesComponent } from './vendors/sri-velu-enterprises/sri-velu-enterprises.component';
import { SuyashGaugesAndInstrumentsComponent } from './vendors/suyash-gauges-and-instruments/suyash-gauges-and-instruments.component';
import { SwaraIndustriesComponent } from './vendors/swara-industries/swara-industries.component';
import { SwastikCadCamComponent } from './vendors/swastik-cad-cam/swastik-cad-cam.component';
import { TajIndustriesComponent } from './vendors/taj-industries/taj-industries.component';
import { TauroSteelProductsComponent } from './vendors/tauro-steel-products/tauro-steel-products.component';
import { TechnokraftEngineeringWorksComponent } from './vendors/technokraft-engineering-works/technokraft-engineering-works.component';
import { TeronMetalComponentsComponent } from './vendors/teron-metal-components/teron-metal-components.component';
import { ToolTechComponent } from './vendors/tool-tech/tool-tech.component';
import { TssmsIndiaPvtLtdComponent } from './vendors/tssms-india-pvt-ltd/tssms-india-pvt-ltd.component';
import { TurboIndustriesPvtLtdComponent } from './vendors/turbo-industries-pvt-ltd/turbo-industries-pvt-ltd.component';
import { UltraCorpotechPvtLtdComponent } from './vendors/ultra-corpotech-pvt-ltd/ultra-corpotech-pvt-ltd.component';
import { VaibhavManufacturingSolutionsPvtLtdComponent } from './vendors/vaibhav-manufacturing-solutions-pvt-ltd/vaibhav-manufacturing-solutions-pvt-ltd.component';
import { VartisEnigneeringPvtLtdComponent } from './vendors/vartis-enigneering-pvt-ltd/vartis-enigneering-pvt-ltd.component';
import { VirajTechnocastPvtLtdComponent } from './vendors/viraj-technocast-pvt-ltd/viraj-technocast-pvt-ltd.component';
import { YashEngineeringWorksComponent } from './vendors/yash-engineering-works/yash-engineering-works.component';
import { GlobalComponent } from './global/global.component';
import { NewSignupFormComponent } from './home/new-signup-form/new-signup-form.component';
import { NewSigninFormComponent } from './home/new-signin-form/new-signin-form.component';
import { ProcurementComponent } from './blog/procurement/procurement.component';
import { NewPricingPageComponent } from './new-pricing-page/new-pricing-page.component';
import { BiddingSignupComponent } from './home/bidding-signup/bidding-signup.component';


const routes: Routes = [
  
  {
    path: "",
    component: LandingpagesComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Get Your Vendor -Best B2B Manufacturing Facility Search Portal | Home",
      
      metaTags:[
        { name: 'description', content: 'Get Your Vendor the best b2b manufacturing marketplace connects vendors and buyers towards facilitating manufacturing multiple categories, which would streamline into global sourcing and custom manufacturing.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'custom-manufacturing,e-commerce'},
      ]
    
        
        }  
    
    }
  },
  
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  },  
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),

      data: {

        seo:{
  
        title:"Login | Get Your Vendor ",
        
        metaTags:[
          { name: 'description', content: 'Get Your Vendor the best b2b manufacturing marketplace connects vendors and buyers towards facilitating manufacturing multiple categories, which would streamline into global sourcing and custom manufacturing.'},
          { name:'robots', content:'noindex, nofollow'},
          {name:'keywords',content:'custom-manufacturing,e-commerce'},
        ]
      
          
          }  
      
      }
  },
  {
    path: "forgotpassword",
    component: ForgotpassComponent,
    pathMatch: "full",

    data:{

      seo:{

        title:"Forgot Password | Get Your Vendor",
      }
    }
  },
  
  {
    path: "contactus",
    component: ContactusComponent,
    pathMatch: "full",

    data: {

      seo:{

      title:"Contact us | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'Contact us for global sourcing and custom manufacturing.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'custom-manufacturing,contact'},
      ]
    
        
        }  
    
    }
  },

  {
    path: "career",
    component: CareerComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Career | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'This is a unique opportunity to work on innovative and disruptive technology-driven business solutions that are shaping the future of the manufacturing industry.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'jobs,open positions,career'},
      ]
    
        
        }  
    
    }
  },
{
    path: "rfq/:no",
    component: PublicRFQComponent,
    pathMatch: "full",

    data: {

      seo:{

      title:"RFQ | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'Contact us for global sourcing and custom manufacturing.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'custom-manufacturing,contact,RFQ'},
      ]
    
        
        }  
    
    }
  },
  {
    path: "aboutus",
    component: AboutusComponent,
    pathMatch: "full",

    data: {

      seo:{

      title:"About Us | Get Your Vendor",
      
      metaTags:[
        {name: 'description', content: 'Get Your Vendor the best b2b manufacturing marketplace '},
        { property: 'og:title', content: 'GYV AboutUs' },
        { proprety: 'og:description', content: 'Custom' },
        { property: 'og:image', content: GlobalComponent.appsrcURL + 'assets/images/media/whyus/vendor.png' },
        { property: 'og:url', content: GlobalComponent.hostName + '/aboutus' },
        {name:'robots', content:'index, follow'},
        {name:'keywords', content:'aboutus'},
      ]
    
        
        }  
    
    }
  },

  {
    path: "blog",
    component: BlogComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Blogs | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'Blogs and articles by our technical team on innovative thinking - Get your Vendor'},
        { name:'robots', content:'index, nofollow'},
        {name:'keywords',content:'custom-manufacturing,e-commerce, vendor management'},
      ]
    
        
        }  
    
    }
  },

  {
    path: "faq",
    component: FaqComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Frequently Asked Questions | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'Get Your Vendor the best b2b manufacturing marketplace.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'faq,subcription plan'},
      ]
    
        
        }  
    
    }
  },


  {
    path: "help",
    component: HelpComponent,
    pathMatch: "full",
  },

  { path: "demo-signup", 
    component:  NewSignupFormComponent,
    pathMatch: "full", 
  },

  { path: "newpricingpage", 
    component: NewPricingPageComponent,
    pathMatch: "full", 
    data: {

      seo:{

      title:"Pricing Page | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'Get Your Vendor the best b2b manufacturing marketplace.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'Subcription plan, Pricing'},
      ]
    
        
        }  
    
    }
  },

  { path: "new-signin-form", 
    component:  NewSigninFormComponent,
    pathMatch: "full", 
  },
  



  {
    path: "terms",
    component: TermsComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Terms of use | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'All users must read and understand the Terms of Get Your Vendor as it has been formulated to safeguard the Indisourcing Terms.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'terms,legal'},
      ]
    
        
        }  
    
    }
  },
  {
    path: "privacy-policy",
    component: PrivacyComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Privacy Policy | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'All users must read and understand the Privacy Policy of Get Your Vendor as it has been formulated to safeguard the user’s privacy or Privacy Policy.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'privacy,legal'},
      ]
    
        
        }  
    
    }
  },

  
  {
    path: "signup",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
      data: {

        seo:{
  
        title:"Get Your Vendor | Signup",
        
        metaTags:[
          { name: 'description', content: 'Get Your Vendor the best b2b manufacturing marketplace connects vendors and buyers towards facilitating manufacturing multiple categories, which would streamline into global sourcing and custom manufacturing.'},
          { name:'robots', content:'noindex, nofollow'},
          {name:'keywords',content:'custom-manufacturing,Signup'},
        ]
      
          
          }  
      
      }
  },
  {
    path:"buyers-benefits",
    component: ForbuyerComponent,
    pathMatch:"full",
    data: {

      seo:{

      title:"Buyers Benefits| Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'Get Your Vendor is a user-friendly platform where Indian and overseas buyers of manufacturing can post their needs in order to connect with Indian and global manufacturers.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'manufacturing,products'},
      ]
    
        
        }  
    
    }

  },
  { path:"vendors-benefits",
    component : ForvendorComponent,
    pathMatch: "full",

    data: {

      seo:{

      title:"Vendor Benefits | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: ' GetYourVendor is a user-friendly platform for Indian and International manufacturing Vendors/Suppliers to connect with Indian and global manufacturing Buyers.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'manufacturing,products'},
      ]
    
        
        }  
    
    }
  },
  {
    path:"blog/Custom-Manufacturing-The-USP-of-distinctive-brands",
    component: CustomfgComponent,
    pathMatch :"full",

    data: {

      seo:{

      title:"Custom manufacturing USP to boost your business | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'The manufacturing or vendor can make or break a business – find a custom manufacturer to help you stand out in a crowd!'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'manufacturing,products'},
      ]
    
        
        }  
    
    }
  },
  { 
    path:"blog/Vendor-Development-strategic-sourcing",
    component : VdsComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Benefits of Vendor Development Strategic Sourcing | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'Strategic sourcing for vendor development involves being current on market trends, altering customer demand, new business models, and technical developments.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'manufacturing,products'},
      ]
    
        
        }  
    
    }
  },
  {
    path:"blog/First-Year-as-a-founder",
    component:CofounderComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"The best solution for Indian Manufacturing Companies | Get Your Vendor ",
      
      metaTags:[
        { name: 'description', content: 'The idea to form Indisourcing Solutions Pvt. Ltd. with like-minded people was to deliver the best solution for Indian manufacturing companies.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'indian ,manufacturing,sme'},
      ]
    
        
        }  
    
    }
  },
  {
    path: "blog/4-Tips-To-Optimize-Your-Supply-Chain-Cost",
    component: SupplychainComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Optimize supply chain cost with 4 strategic steps | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'One of the key KPIs for every manager in a firm is cost minimization in the supply chain. If done incorrectly, it poses a serious threat to any companys finances and expansion.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'indian ,manufacturing,sme'},
      ]
    
        
        }  
    
    }
  },
  {
    path: "blog/Budget-2021-What-does-it-have-in-store-for-the-Manufacturing-Industry",
    component: Budget2021Component,
    pathMatch: "full",
    data: {

      seo:{

      title:"How will the budget for 2021 affect the manufacturing sector?",
      
      metaTags:[
        { name: 'description', content: 'Times were uncertain, businesses were on the brink of oblivion and there was no solution in sight – in such turbulent circumstances, all eyes were on Budget-2021 in hopes that it would help the Manufacturing industry get back on its feet.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'indian ,manufacturing,sme'},
      ]
    
        
        }  
    
    }
  },
  {
    path:"blog/Procurement-Management-System",
    component: GyvbComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Procurement management platform to get your vendor",
      
      metaTags:[
        { name: 'description', content: 'Procurement managers, Sourcing teams and Design engineers spend a lot of time searching and evaluating vendors. This kind of analysis and research involves heavy costs both in terms of time and money.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'indian ,manufacturing,sme'},
      ]
    
        
        }  
    
    }
  },
  {
    path: "blog/The-Manufacturing-Industry-Behind-the-Scenes",
    component: BehindsComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Study the challenges faced by the ever-evolving Manufacturing Industry",
      
      metaTags:[
        { name: 'description', content: 'To devise 360-degree solutions, it’s important to first understand the problem! Let’s study the challenges faced by the ever-evolving Manufacturing industry and then move on to the solution'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'indian ,manufacturing,sme'},
      ]
    
        
        }  
    
    }
  },
  {
    path: "blog/How-to-Find-Reliable-Indian-Manufacturing-Vendors",
    component: ManufacturingComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"4 steps to find your reliable Indian Manufacturer",
      
      metaTags:[
        { name: 'description', content: 'If you are planning to sell your products in India, in that case, you might be willing to connect with vendors that can act as manufacturers, distributors, and wholesalers lets directly delve into ways you can connect with local vendors to manufacture in India.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'manufacturing,products'},
      ]
    
        
        }  
    
    }
  },
  {
    path: "blog/Why-Procurement-Needs-To-Look-Beyond-The-Traditional-Methods",
    component: ProcurementComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Why Procurement Needs To Look Beyond The Traditional Methods",
      
      metaTags:[
        { name: 'description', content: 'Its time for procurement to break free from the shackles of tradition and embark on a transformative journey towards a more agile, strategic, and value-driven future.'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'procurement,products'},
      ]
    
        
        }  
    
    }
  },



  

  {
    path: "testimonials",
    component: TestimonialsComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Testimonials | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'Users feedback for our excellent service'},
        { name:'robots', content:'index, follow'},
        { name:'keywords',content:'manufacturing,testimonials'},
      ]
    
        
        }  
    
    }

  },

  {
    path: "strategicpartner",
    component: StrategicpartnerComponent,
    pathMatch: "full",
    data: {

      seo:{

      title:"Strategic partnership Program | Get Your Vendor",
      
      metaTags:[
        { name: 'description', content: 'Get Your Vendor offers the strategic partnership program to similar interest individuals or organizations experts in the following areas: Vendor Development, Sourcing Consultants, Purchase Experts, Procurement, Distribution Organizations'},
        { name:'robots', content:'index, follow'},
        {name:'keywords',content:'privacy,legal'},
      ]
    
        
        }  
    
    }
  },
  {
    path: "siddharthengineering",
    component: SiddharthengineeringComponent,
    pathMatch: "full",
  },
  {
    path:"aaditya-industries",
    component:AadityaIndustriesComponent,
    pathMatch: "full"
  },
  {
    path:"aadveek-enterprises",
    component:AadveekEnterprisesComponent,
    pathMatch: "full"
  },
  {
    path:"abo-autocom",
    component: AboAutocomComponent,
    pathMatch: "full"
  },
  {
    path:"accusion-engineering-pvt-ltd",
    component: AccusionEngineeringPvtLtdComponent,
    pathMatch: "full"
  },
  {
    path:"accutech-laser-pvt-ltd",
    component: AccutechLaserPvtLtdComponent,
    pathMatch: "full"
  },
  {
    path:"aditya-autos",
    component:AdityaAutosComponent,
    pathMatch: "full"
  },
  {
    path:"aim-precision-industries-pvt-ltd",
    component:AimPrecisionIndustriesPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"airgrip-industries",
    component:AirgripIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"almond-engineers",
    component:AlmondEngineersComponent,
    pathMatch:"full" 
  },
  {
    path:"aludyco-mfg-co",
    component:AludycoMfgCoComponent,
    pathMatch:"full" 
  },
  {
    path:"alumina-india-casting-company",
    component:AluminaIndiaCastingCompanyComponent,
    pathMatch:"full" 
  },
  {
    path:"aps-tech-enterprises",
    component:ApsTechEnterprisesComponent,
    pathMatch:"full" 

  },
  {
    path:"asian-machine-tool-corporation-pvt-ltd",
    component:AsianMachineToolCorporationPvtLtdComponent,
    pathMatch:"full" 
  },
  {
    path:"auto-international",
    component:AutoInternationalComponent,
    pathMatch:"full" 
  },
  {
    path:"automatic-engineering-works",
    component:AutomaticEngineeringWorksComponent,
    pathMatch:"full" 
  },
  {
    path:"avn-enterprises",
    component:AvnEnterprisesComponent,
    pathMatch:"full" 

  },
  {
    path:"beejay-industries-pvt-ltd",
    component:BeejayIndustriesPvtLtdComponent,
    pathMatch:"full" 

  },
  {
    path:"bhoruka-fabcons-pvt-ltd",
    component:BhorukaFabconsPvtLtdComponent,
    pathMatch:"full" 

  },
  {
    path:"bokhara-industries-pvt-ltd",
    component:BokharaIndustriesPvtLtdComponent,
    pathMatch:"full" 
  },
  {
    path:"contact-bearing-co-pvt-ltd",
    component:ContactBearingCoPvtLtdComponent,
    pathMatch:"full" 
  },
  {
    path:"creative-engineers",
    component:CreativeEngineersComponent,
    pathMatch:"full"
  },
  {
    path:"creative-tools-and-components",
    component:CreativeToolsAndComponentsComponent,
    pathMatch:"full"
  },
  {
    path:"dattaguru-industries-pvt-ltd",
    component:DattaguruIndustriesPvtLtdComponent,
    pathMatch:"full" 
  },
  {
    path:"dcruz-gears-india-pvt-ltd",
    component:DcruzGearsIndiaPvtLtdComponent,
    pathMatch:"full" 
  },
  {
    path:"deccan-industries",
    component:DeccanIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"delta-pi-engineering",
    component:DeltaPiEngineeringComponent,
    pathMatch:"full"
  },
  {
    path:"dimension-enterprise",
    component:DimensionEnterpriseComponent,
    pathMatch:"full"
  },
  {
    path:"drastik-engineering-company",
    component:DrastikEngineeringCompanyComponent,
    pathMatch:"full"
  },
  {
    path:"ds-foundry",
    component:DsFoundryComponent,
    pathMatch:"full"
  },
  {
    path:"durable-metalcraft",
    component:DurableMetalcraftComponent,
    pathMatch:"full"
  },
  {
    path:"efficnc-engineering-llp",
    component:EfficncEngineeringLlpComponent,
    pathMatch:"full"
  },
  {
    path:"engeetech-engineerings",
    component:EngeetechEngineeringsComponent,
    pathMatch:"full"
  },
  {
    path:"essae-gears-and-transmissions-pvt-ltd",
    component:EssaeGearsAndTransmissionsPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"extensive-manufacturing-solutions",
    component:ExtensiveManufacturingSolutionsComponent,
    pathMatch:"full"
  },
  {
    path:"fix-yantra-engineering",
    component:FixYantraEngineeringComponent,
    pathMatch:"full"
  },
  {
    path:"ganesh-industries",
    component:GaneshIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"gemba-metal-forming-pvt-ltd",
    component:GembaMetalFormingPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"ghanshyam-machining",
    component:GhanshyamMachiningComponent,
    pathMatch:"full"
  },
  {
    path:"gunjals-industries",
    component:GunjalsIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"har-engineering-traders",
    component:HarEngineeringTradersComponent,
    pathMatch:"full"
  },
  {
    path:"hexagon-indurstries",
    component:HexagonIndurstriesComponent,
    pathMatch:"full"
  },
  {
    path:"hi-tech-enterprises",
    component:HiTechEnterprisesComponent,
    pathMatch:"full"
  },
  {
    path:"horizon-steels-and-engineering",
    component:HorizonSteelsAndEngineeringComponent,
    pathMatch:"full"
  },
  {
    path:"hynix-technologies",
    component:HynixTechnologiesComponent,
    pathMatch:"full"
  },
  {
    path:"i-fab-engineering-pvt-ltd",
    component:IFabEngineeringPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"innovative-industries",
    component:InnovativeIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"innovic-technology",
    component:InnovicTechnologyComponent,
    pathMatch:"full"
  },
  {
    path:"jagdish-precision",
    component:JagdishPrecisionComponent,
    pathMatch:"full"
  },
  {
    path:"jyothi-engineering-works",
    component:JyothiEngineeringWorksComponent,
    pathMatch:"full"
  },
  {
    path:"kalariya-industries",
    component:KalariyaIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"kalpak-precision-industries",
    component:KalpakPrecisionIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"kedar-precision-components",
    component:KedarPrecisionComponentsComponent,
    pathMatch:"full"
  },
  {
    path:"kepio-engineering-services-pvt-ltd",
    component:KepioEngineeringServicesPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"khavare-engineering",
    component:KhavareEngineeringComponent,
    pathMatch:"full"
  },
  {
    path:"khk-industries",
    component:KhkIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"kirdak-autocom-pvt-ltd",
    component:KirdakAutocomPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"krupa-engineering",
    component:KrupaEngineeringComponent,
    pathMatch:"full"
  },
  {
    path:"kuber-enterprises",
    component:KuberEnterprisesComponent,
    pathMatch:"full"
  },
  {
    path:"mahavir-engineering",
    component:MahavirEngineeringComponent,
    pathMatch:"full"
  },
  {
    path:"marsh-precision-engineering",
    component:MarshPrecisionEngineeringComponent,
    pathMatch:"full"
  },
  {
    path:"maxwell-auto-components-pvt-ltd",
    component:MaxwellAutoComponentsPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"meedaas-protek-pvt-ltd",
    component:MeedaasProtekPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"metalls-international",
    component:MetallsInternationalComponent,
    pathMatch:"full"
  },
  {
    path:"microtech-precision",
    component:MicrotechPrecisionComponent,
    pathMatch:"full"
  },
  {
    path:"mm-technologies",
    component:MmTechnologiesComponent,
    pathMatch:"full"
  },
  {
    path:"multi-technology",
    component:MultiTechnologyComponent,
    pathMatch:"full"
  },
  {
    path:"nikash-cnc-engineers-pvt-ltd",
    component:NikashCncEngineersPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"nirmala-engineering",
    component:NirmalaEngineeringComponent,
    pathMatch:"full"
  },
  {
    path:"omkar-enterprises",
    component:OmkarEnterprisesComponent,
    pathMatch:"full"
  },
  {
    path:"omkar-industries",
    component:OmkarIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"om-kartik",
    component:OmKartikComponent,
    pathMatch:"full"
  },
  {
    path:"oviyan-cast-n-forge-pvt-ltd",
    component:OviyanCastNForgePvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"p-and-p-enterprises",
    component:PAndPEnterprisesComponent,
    pathMatch:"full"
  },
  {
    path:"parag-metals",
    component:ParagMetalsComponent,
    pathMatch:"full"
  },
  {
    path:"parishram-engineers",
    component:ParishramEngineersComponent,
    pathMatch:"full"
  },
  {
    path:"parth-manufacturing-pvt-ltd",
    component:ParthManufacturingPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"patel-brass-turnomatics-pvt-ltd",
    component:PatelBrassTurnomaticsPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"penta-metal-solutions",
    component:PentaMetalSolutionsComponent,
    pathMatch:"full"
  },
  {
    path:"perfect-tools-and-gauges",
    component:PerfectToolsAndGaugesComponent,
    pathMatch:"full"
  },
  {
    path:"pi-industries",
    component:PiIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"pooja-engineering-works",
    component:PoojaEngineeringWorksComponent,
    pathMatch:"full"
  },
  {
    path:"poona-pressings-pvt-ltd",
    component:PoonaPressingsPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"power-control-electro-systems-pvt-ltd",
    component:PowerControlElectroSystemsPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"praneel-industries",
    component:PraneelIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"prasannaengineers",
    component:PrasannaengineersComponent,
    pathMatch:"full"
  },
  {
    path:"precimetal-works",
    component:PrecimetalWorksComponent,
    pathMatch:"full"
  },
  {
    path:"precision-industries",
    component:PrecisionIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"precitech-forgings-and-engineering-pvt-ltd",
    component:PrecitechForgingsAndEngineeringPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"press-metal-industries",
    component:PressMetalIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"puja-fluid-seals-pvt-ltd",
    component:PujaFluidSealsPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"pushpak-engineering-india-pvt-ltd",
    component:PushpakEngineeringIndiaPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"quality-engineering-co",
    component:QualityEngineeringCoComponent,
    pathMatch:"full"
  },
  {
    path:"raghav-forge-pvt-ltd",
    component:RaghavForgePvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"raks-aeroengineering",
    component:RaksAeroengineeringComponent,
    pathMatch:"full"
  },
  {
    path: 'page-not-found',
    component: NotFoundComponent
  },
  {
    path:"rameshwar-enterprises",
    component:RameshwarEnterprisesComponent,
    pathMatch:"full"
  },
  {
    path:"ramji-springs",
    component:RamjiSpringsComponent,
    pathMatch:"full"
  },
  {
    path:"range-precise",
    component:RangePreciseComponent,
    pathMatch:"full"
  },
  {
    path:"rawgold-metal",
    component:RawgoldMetalComponent,
    pathMatch:"full"
  },
  {
    path:"reflect-additive",
    component:ReflectAdditiveComponent,
    pathMatch:"full"
  },
  {
    path:"rixon-fasteners-pvt-ltd",
    component:RixonFastenersPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"robust-precise-engineering",
    component:RobustPreciseEngineeringComponent,
    pathMatch:"full"
  },
  {
    path:"rohit-group-of-industries",
    component:RohitGroupOfIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"royal-engineering-and-solution",
    component:RoyalEngineeringAndSolutionComponent,
    pathMatch:"full"
  },
  {
    path:"rr-industries",
    component:RrIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"sadhu-forging-ltd",
    component:SadhuForgingLtdComponent,
    pathMatch:"full"
  },
  {
    path:"sagar-industries",
    component:SagarIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"saienterprises",
    component:SaienterprisesComponent,
    pathMatch:"full"
  },
  {
    path:"sai-enterprises",
    component:SaiEnterprisesComponent,
    pathMatch:"full"
  },
  {
    path:"sanjay-techno-products-pvt-ltd",
    component:SanjayTechnoProductsPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"sap-engineers",
    component:SapEngineersComponent,
    pathMatch:"full"
  },
  {
    path:"sarvoday-engineering",
    component:SarvodayEngineeringComponent,
    pathMatch:"full"
  },
  {
    path:"sasa-enggtech-pvt-ltd",
    component:SasaEnggtechPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"savitri-enterprises",
    component:SavitriEnterprisesComponent,
    pathMatch:"full"
  },
  {
    path:"scanda-industries",
    component:ScandaIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"shakalya-enterprises",
    component:ShakalyaEnterprisesComponent,
    pathMatch:"full"
  },
  {
    path:"shantdeep-metals-pvt-ltd",
    component:ShantdeepMetalsPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"sharanyapolymer",
    component:SharanyapolymerComponent,
    pathMatch:"full"
  },
  {
    path:"shelar-engitech-pvt-ltd",
    component:ShelarEngitechPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"shivanand-pressing",
    component:ShivanandPressingComponentsComponent,
    pathMatch:"full"
  },
  {
    path:"shiv-spares",
    component:ShivSparesComponent,
    pathMatch:"full"
  },
  {
    path:"shree-chamunda-krupa-industry",
    component:ShreeChamundaKrupaIndustryComponent,
    pathMatch:"full"
  },
  {
    path:"shree-sai-industries",
    component:ShreeSaiIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"shree-swami-samarth-engineering",
    component:ShreeSwamiSamarthEngineeringComponent,
    pathMatch:"full"
  },
  {
    path:"shri-datta-industries",
    component:ShriDattaIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"shri-engineers",
    component:ShriEngineersComponent,
    pathMatch:"full"
  },
  {
    path:"shriniwas-machine-craft-pvt-ltd",
    component:ShriniwasMachineCraftPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"shrivin-technologies-llp",
    component:ShrivinTechnologiesLlpComponent,
    pathMatch:"full"
  },
  {
    path:"shrushti-industries",
    component:ShrushtiIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"shubhneel-industries",
    component:ShubhneelIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"sk-technolgies",
    component:SkTechnologiesComponent,
    pathMatch:"full"
  },
  {
    path:"solaria-technology-pvt-ltd",
    component:SolariaTechnologyPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"somesh-forge-pvt-ltd",
    component:SomeshForgePvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"sona-industries",
    component:SonaIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"speed-craft-industries",
    component:SpeedCraftIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"square-engineering",
    component:SquareEngineeringComponent,
    pathMatch:"full"
  },
  {
    path:"srdcorp-india-pvt-ltd",
    component:SrdcorpIndiaPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"sri-velu-enterprises",
    component:SriVeluEnterprisesComponent,
    pathMatch:"full"
  },
  {
    path:"suyash-gauges-and-instruments",
    component:SuyashGaugesAndInstrumentsComponent,
    pathMatch:"full"

  },
  {
    path:"swara-industries",
    component:SwaraIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"swastik-cad-cam",
    component:SwastikCadCamComponent,
    pathMatch:"full"
  },
  {
    path:"taj-industries",
    component:TajIndustriesComponent,
    pathMatch:"full"
  },
  {
    path:"tauro-steel-products",
    component:TauroSteelProductsComponent,
    pathMatch:"full"
  },
  {
    path:"technokraft-engineering-works",
    component:TechnokraftEngineeringWorksComponent,
    pathMatch:"full"
  },
  {
    path:"teron-metal-components",
    component:TeronMetalComponentsComponent,
    pathMatch:"full"
  },
  {
    path:"tool-tech",
    component:ToolTechComponent,
    pathMatch:"full"
  },
  {
    path:"tssms-india-pvt-ltd",
    component:TssmsIndiaPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"turbo-industries-pvt-ltd",
    component:TurboIndustriesPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"ultra-corpotech-pvt-ltd",
    component:UltraCorpotechPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"vaibhav-manufacturing-solutions-pvt-ltd",
    component:VaibhavManufacturingSolutionsPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"vartis-enigneering-pvt-ltd",
    component:VartisEnigneeringPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"viraj-technocast-pvt-ltd",
    component:VirajTechnocastPvtLtdComponent,
    pathMatch:"full"
  },
  {
    path:"yash-engineering-works",
    component:YashEngineeringWorksComponent,
    pathMatch:"full"
  },
  {
    path:"bid",
    component:BiddingSignupComponent,
    pathMatch:"full"
  },
  {
    path: '**',
    redirectTo: '/page-not-found'
  },

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
