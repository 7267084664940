
    <login-home-navigation></login-home-navigation>
        


<section class="sptb innerpage-margin" style="background-color: #fff;">
    <div class="container">

        <div class="row">
        
            <div class="sptb col-lg-12 col-md-12 col-sm-12">
                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-lg-12 col-md-12  col-sm-12">
                        <!-- <div class="card blog-detail"> -->
                            <!-- <div class="card-body"> -->
								<h1 style="text-align: center;">Buyer Benefits</h1>
								
								<div class="blog-detail">
                  <p>

                    The COVID-19 pandemic has been a challenging time for manufacturing companies worldwide. Many have had to shut down due to a lack of manpower, work, funds, and vision. However, there is a silver lining. The companies that diversified and embraced the internet managed to keep going. As India makes its impact on the world, the government is taking steps to ensure Indian companies become financially independent, and we're here to support them. We're bringing Indian companies to the internet to showcase their technical ability to deliver quality goods. Let's take pride in #IndianManufacturing, #DigitalIndia, and being #FutureReady. Join us in the movement to make India a global manufacturing hub.
                  </p>
								    <img src="assets/images/media/buyer.webp" alt="vendor sourcing" width="100%" height="auto">
                    <p>Looking to connect with manufacturing suppliers from India and all around the world?</p><p> Look no further than GetYourVendor! Our user-friendly platform empowers you to publish your requirements and connect with the right suppliers.</p>
                    <p> By using our platform, you can expect reduced vendor development costs and extended team efforts. Our features like RFQ publishing, NDA protection, and advanced search options make finding a specific supplier/vendor a breeze.</p>
                    <p> Our buyer dashboard and vendor profile feature allow you to track each RFQ, NDA, vendor/supplier, and final date of RFQs making the process even more seamless. Join us now and experience the benefits of specific supplier requirements, digital NDA, virtual vendor assessment, quotation tracking, and supplier tracking.</p>
                    <p> Take control of your procurement process with GetYourVendor!</p>
                    <p class="fa fa-book"> <a href="assets/images/media/ebook/Buyer_ROI.pdf" target="_blank" title="Buyers_Ebook">Download Buyers_Ebook </a></p>


                 </div>
							</div>
						<!-- </div> -->
                    <!-- </div> -->
                   
                </div>
               
                <!--/Itemsd lists-->
            </div>
            <!--Right Side Content-->
         
            <!--/Right Side Content-->
        </div>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <img src="assets/images/media/buyersection.webp" alt="sourcing" width="100%" height="auto">
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <a href="login/signup" >  <img src="assets/images/media/rfqsection.webp" alt="rfq sourcing" width="100%" height="auto"></a>
          </div>
        </div>
    </div>
</section><!--/Section-->
<section class="sptb" style="background-color: #fff;" >
  <div class="container"> 
    <div class="row" style="filter: grayscale(100%);">
      <marquee  behavior="scroll" direction="left">        
        <img src=
        "assets/images/media/buyer1.webp"
        alt="buyer1"> 
      </marquee>  
      <marquee  behavior="scroll" direction="right">        
        <img src=
        "assets/images/media/buyer2.webp"
        alt="buyer1"> 
      </marquee>  
      <marquee  behavior="scroll" direction="left">        
        <img src=
        "assets/images/media/buyer3.webp"
        alt="buyer1"> 
      </marquee>  
    </div>
</div>
</section>
<section class="sptb" style="background-color: #fff;">
    <div class="container">
      <h2>KNOWLEDGE READING</h2>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 blog-detail">
          <p><li><a routerLink = "../blog/How-to-Find-Reliable-Indian-Manufacturing-Vendors" >How to Find Reliable Indian Manufacturing Vendors</a></li></p>
          <p><li><a routerLink = "../blog/Custom-Manufacturing-The-USP-of-distinctive-brands" >Custom Manufacturing The USP of distinctive brands</a></li></p>
          <p><li><a routerLink = "../blog/Vendor-Development-strategic-sourcing" >Vendor Development Strategic Sourcing</a></li></p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 blog-detail">
          <p><li><a routerLink = "../blog/4-Tips-To-Optimize-Your-Supply-Chain-Cost" >4 Tips To Optimize Your Supply Chain Cost</a></li></p>
          <p><li><a routerLink = "../blog/Procurement-Management-System" >Procurement Management System</a></li></p>
          <p><li><a routerLink = "../blog/The-Manufacturing-Industry-Behind-the-Scenes" >The Manufacturing Industry Behind the Scenes</a></li></p>
        </div>
      </div>
    </div>

  </section>
  <section class="sptb" style="background-color: #fff;" >
    <div class="container"> 
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <a href="login/signup" > <img src=
            "assets/images/media/vendorbtn.webp"
            alt="vendor"> </a>

        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <a href="login/signup" ><img src=
          "assets/images/media/buyerbtn.webp"
          alt="buyer"> </a>
        </div>
      </div>
  
    </div>
  
  </section>
<login-home-footer></login-home-footer>