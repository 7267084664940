<login-home-navigation></login-home-navigation>
<section>
<div class="container mt-8">
    <h1><b>Vendor Membership Plans</b></h1>  
    <div class="col-lg-12 col-md-12 col-sm-12">
      <p style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">Welcome to membership calculator, we have multiple membership plans as per your budget !
      Set your budget value using the slider, and we will suggest the best plans to help you secure your next order.</p>


    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-slider 
      thumbLabel
      [displayWith]="formatLabel"
      tickInterval="auto"
      (valueChange)="onChange($event)" 
      min="5"
      max="100"
      value="value"></mat-slider>
      <!-- <p>Your Budget plan value: {{finalvalue}}000</p> -->
      </div>
      <div>
      <mat-form-field>
        <mat-label>Your Budget plan value:</mat-label>
        <input matInput type="number" onKeyPress="if(this.value.length==6) return false;" min="5000" max="100000" [(ngModel)]="finalvalue" />
      </mat-form-field>
   
    </div>
    
    <div class="row">
      <!-- Card 1 -->
        <div class="col-sm-4 col-md-4 col-lg-4">
        <div *ngIf="finalvalue >= 0" class="card">
          <img src="/assets/images/media/free_pack.png">
        </div>
      </div>
  
      <!-- Card 2 -->
     
      <div class="col-sm-4 col-md-4 col-lg-4">
          <div *ngIf="finalvalue >=5000 && finalvalue !== 11001" class="card"  >
            <img src="/assets/images/media/life+pricing.png">
          </div>
        
      </div>

      <div class="col-sm-4 col-md-4 col-lg-4">
        <div *ngIf="finalvalue >= 11001 && finalvalue !== 45001"   >
          <img src="/assets/images/media/gold_pricing.png">
        </div>
      
    </div>

  
        <div class="col-sm-4 col-md-4 col-lg-4">
        <div *ngIf="finalvalue >= 45001 && finalvalue !== 65001" class="card" >
          <img src="/assets/images/media/plat_pricing.png">
        </div>
      </div>

      <div class="col-sm-4 col-md-4 col-lg-4">
        <div *ngIf="finalvalue >= 65001 && finalvalue !== 100001" class="card" >
          <img src="/assets/images/media/plat+pricing.png">
        </div>
      </div>

    </div>
  </div>
<div class="row">
  <div class="col-lg-4">

  </div>
  <div class="col-lg-4">

  </div>
  
  <div class="col-lg-4 ">
    <p style="align-content: end;"><button class="btn-primary" (click)="openDialog()" >Request Membership Pricing </button></p>
  </div>
</div>
</section>
<section class="innerpage-margin">
  <div class="container" style="align-items: center;">
  
    <img src="/assets/images/media/home/land_banner.webp">
   

  
    </div>

</section>
<login-home-footer></login-home-footer>