
    <!-- Mobile Header -->
    <div id="sticky-wrapper" class="sticky-wrapper" style="height: 31.5833px;">
        <div class="horizontal-header bg-dark-transparent clearfix " >
            <div class="container">
                <a id="horizontal-navtoggle" (click)="happy()" class="animated-arrow"><span></span></a>
                <span class="smllogo"><img src="assets/images/brand/logo-dark.webp" alt="gyv" width="50%" height = "100%" loading="lazy"></span>
                <span class="smllogo-white"><img src="assets/images/brand/logo.webp" alt="gyv" width="50%" height = "100%"  loading="lazy"></span>
                
                <a routerLink="/login" class="callusbtn"><i class="fa fa-user" aria-hidden="true"></i></a>
            </div>
        </div>
    </div>

 

    <!--Horizontal-main -->
    <div id="sticky-wrapper" class="sticky-wrapper" id="t-navbar">
        <div class="horizontal-main header-style1  bg-dark-transparent clearfix">
            <div class="horizontal-mainwrapper container clearfix">
                <div class="desktoplogo">
                    <a href="/">
                        <img src="assets/images/brand/logo1.png" width="50%" height="50%" alt="gyv" class="header-brand-img1" loading="lazy">
                        <img src="assets/images/brand/logo.webp" width="50%" height="50%" class="header-brand-img header-white" alt="gyvlogo" loading="lazy">
                    </a>
                </div>
                <div class="desktoplogo-1">
                    <a href="/">
                        <img src="assets/images/brand/logo1.png" width="50%" height="50%" alt="gyv" class="header-brand-img1" loading="lazy">
                        <img src="assets/images/brand/logo.webp" width="50%" height="50%" class="header-brand-img header-white" alt="logo" loading="lazy">
                    </a>
                    
                </div>
               
                <nav class="horizontalMenu clearfix d-md-flex">
                    <div class="horizontal-overlapbg"></div>
                    <ul class="horizontalMenu-list">
                        <li aria-haspopup="true"><a [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }" routerLink="/" >Home </a>

                        </li>
                        <li aria-haspopup="true"><span class="horizontalMenu-click horizontal-activearrow"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span><a style="cursor: pointer;">For Buyers <span class="fe fe-chevron-down m-0"></span></a>
                            <ul class="sub-menu" style="display: block;">
                                 <li><a [routerLinkActive]="'active'" routerLink="/buyers-benefits">Buyers Benefits</a></li>
                                 <li><a  [routerLinkActive]="'active'" routerLink="/login">Request a Custom Part Quote</a></li> 
                                 <li><a  [routerLinkActive]="'active'" routerLink="/testimonials">Testimonials</a></li> 
                            </ul>
                        <li aria-haspopup="true"><span class="horizontalMenu-click horizontal-activearrow"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span><a style="cursor: pointer;">For Vendors<span class="fe fe-chevron-down m-0"></span></a>
                            <ul class="sub-menu" style="display: block;">
                                <li><a [routerLinkActive]="'active'" routerLink="/vendors-benefits">Vendors Benefits</a></li>
                                <li><a [routerLinkActive]="'active'" routerLink="/login">Quote Custom Part</a></li>
                                <!-- <li><a [routerLinkActive]="'active'" ><button (click)="openDialog()">Display Products <i style="vertical-align: super ; font-size: smaller; color: red;">New</i></button> </a> </li> -->
                                <li><a  [routerLinkActive]="'active'" routerLink="/testimonials">Testimonials</a></li> 
                            </ul>
                      
                         <li aria-haspopup="true"><span class="horizontalMenu-click horizontal-activearrow"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span><a style="cursor: pointer;">Company<span class="fe fe-chevron-down m-0"></span></a> 
                            <ul class="sub-menu" style="display: block;">
                                <li><a [routerLinkActive]="'active'" routerLink="/aboutus" >About Us</a></li>
                                <!-- <li><a [routerLinkActive]="'active'" routerLink="/aboutus" (click)='setDocTitle( "Best B2B custom manufacturing facility search portal-GetYourVendor", "Get Your Vendor the best b2b manufacturing marketplace connects vendors and buyers towards facilitating manufacturing multiple categories, which would streamline into global sourcing and custom manufacturing." )'>Team</a></li> -->
                                <li><a [routerLinkActive]="'active'" routerLink="/blog">Blogs</a></li>
                                <li><a [routerLinkActive]="'active'" routerLink="/career">Careers</a></li>
                                <li><a  [routerLinkActive]="'active'" routerLink="/testimonials">Testimonials</a></li> 
                                <li><a [routerLinkActive]="'active'" routerLink="/contactus">Contact Us</a></li>
                                <li><a [routerLinkActive]="'active'" routerLink="/strategicpartner">Partner Program</a></li>
                                <li><a [routerLinkActive]="'active'" routerLink="/newpricingpage">Pricing </a></li>
                                <li><a [routerLinkActive]="'active'" routerLink="/faq">FAQ's</a></li>
                               
                            </ul>
                        <!-- </li> --> 
                       
                        <!-- <li aria-haspopup="true"><a href="widgets.html">Services</a></li> -->

                        <!-- <li aria-haspopup="true"><span class="horizontalMenu-click"><i
                                    class="horizontalMenu-arrow fa fa-angle-down"></i></span><a style="cursor: pointer;">Pricing <span
                                    class="fe fe-chevron-down m-0"></span></a>
                            <ul class="sub-menu" style="display: block;">
                                <li><a [routerLinkActive]="'active'" routerLink="/pricing">Demostic</a></li>
                                <li><a [routerLinkActive]="'active'" routerLink="/buyerpricing">International</a></li>

                            </ul>
                        </li> -->

                        <!-- <li aria-haspopup="true"><a [routerLinkActive]="'active'" routerLink="/pricing/{{usertype}}/global">Pricing</a></li> -->
                        <!-- <li aria-haspopup="true"><a [routerLinkActive]="'active'" routerLink="/help">Resources</a></li> -->
                        <!-- <li aria-haspopup="true"><a [routerLinkActive]="'active'" routerLink="/rfq" (click)='setDocTitle( "Best B2B custom manufacturing facility search portal-GetYourVendor", "Get Your Vendor the best b2b manufacturing marketplace connects vendors and buyers towards facilitating manufacturing multiple categories, which would streamline into global sourcing and custom manufacturing." )'>RFQ's</a></li> -->
                        <!-- <li aria-haspopup="true"><a [routerLinkActive]="'active'" routerLink="/contactus" (click)='setDocTitle( "Best B2B custom manufacturing facility search portal-GetYourVendor", "Get Your Vendor the best b2b manufacturing marketplace connects vendors and buyers towards facilitating manufacturing multiple categories, which would streamline into global sourcing and custom manufacturing." )'>Contact Us</a></li> -->
                        <!-- <li aria-haspopup="true"><a [routerLinkActive]="'active'" routerLink="/faq" (click)='setDocTitle( "Best B2B custom manufacturing facility search portal-GetYourVendor", "Get Your Vendor the best b2b manufacturing marketplace connects vendors and buyers towards facilitating manufacturing multiple categories, which would streamline into global sourcing and custom manufacturing." )'>FAQ's</a></li> -->
                        <!-- <li aria-haspopup="true"><a [routerLinkActive]="'active'" routerLink="/login" (click)='setDocTitle( "Best B2B custom manufacturing facility search portal-GetYourVendor", "Get Your Vendor the best b2b manufacturing marketplace connects vendors and buyers towards facilitating manufacturing multiple categories, which would streamline into global sourcing and custom manufacturing." )'>Login</a></li> -->
                        <li aria-haspopup="true"><span class="horizontalMenu-click horizontal-activearrow"><i class="horizontalMenu-arrow fa fa-angle-down"></i></span><a style="cursor: pointer;">Login<span class="fe fe-chevron-down m-0"></span></a>
                        <ul class="sub-menu" style="display: block;">
                            <li><a [routerLinkActive]="'active'" routerLink="/login" title="Already User">Login</a></li>
                            <li><a [routerLinkActive]="'active'" routerLink="/login/signup" title="New to GetYourVendor" >Sign Up</a></li>
                            <!-- <li><a [routerLinkActive]="'active'" routerLink="/login/signup2" title="New to GetYourVendor" >Quick Sign Up</a></li> -->
                        </ul>

                        <!--<li><a class="navbutton"  [routerLinkActive]="'active'" routerLink="/login/signup2" style="color: rgb(12, 1, 1);">14 DAYS FREE TRIAL</a>-->
                            <!-- <li><a class="trial" [routerLinkActive]="'active'" routerLink="/login/signup"><b>14 DAYS FREE TRIAL</b></a></li> -->
                            <li class="tiral"><a [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }" routerLink="/login/signup" >FREE TRIAL </a>
    
                        

                    </ul>
                    
                    

                </nav>
                <!-- <div id="mybutton1">
                    <button class="feedback">Request Demo</button> -->
                    <!-- <button (click)="openDialog()" class="btn btn-info" >Schedule a Call Back!</button>	 -->
                  <!-- </div>  -->
                
            </div>
        </div>
        
    </div>
    <!-- <ng-template #customListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
        <ul class="custom-list-group" [class.dropdown-menu]="isBs3">
            <li class="custom-list-group-item" *ngFor="let match of matches"
                [class.active]="typeaheadTemplateMethods.isActive(match)"
                (click)="typeaheadTemplateMethods.selectMatch(match, $event)"
                (mouseenter)="typeaheadTemplateMethods.selectActive(match)">
                {{ match }}
            </li>
        </ul>
    </ng-template> -->
<!--/Horizontal-main -->