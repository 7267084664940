import { Component, OnInit } from '@angular/core';
//import { MatCarousel, MatCarouselComponent } from '@ngbmodule/material-carousel'; // -------- important
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
//import Swal from 'sweetalert2';
import { AlertService } from '../service/alert.service';
import { AuthenticationService } from '../service/authentication.service';
//import { first } from 'rxjs/operators';
//import { BehaviorSubject } from 'node_modules/rxjs';
//import { EmbedVideoService } from 'ngx-embed-video';


// interface State {
//   page: number;
//   per_page: number;
//  // total: number;
// }
@Component({
  selector: 'login-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  // youtubeUrl = 'https://www.youtube.com/watch?v=NXCx3RKJ3O8';

  // youtubeId = 'NXCx3RKJ3O8';
  // paginate = new BehaviorSubject<State>({
  //   page: 1,
  //   per_page: 10,
    
  // });
  
  // name: any = "";
  // email: any = "";
  // subject: any = "";
  // message: any = "";
  // phone: any ="";
  // rfqloader: boolean;
  // activerfqall: any;
  // offset: any;
  // closeDate: Date;
  // closeMonth: number;

  
  constructor(public toastr: ToastrManager,
    private route: ActivatedRoute, private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,) 
    {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
     // console.log(embedService.embed(this.youtubeUrl));

     }

     
  // homeslides = [
    
  //   {'image': "assets//images//media//home//vendor.png"},
  //   {'image': "assets//images//media//home//buyer.png"},
  //   {'image': "assets//images//media//home//customman.png"},
  //   {'image': "assets//images//media//home//pms.png"},
  //   {'image': "assets//images//media//home//vms.png"}

    // \app\payments
  // ];
  
  // pslides = [
    
  //   {'image': "assets//images//media//premium//pre1.png"},
  //   {'image': "assets//images//media//premium//pre2.png"},
  //   {'image': "assets//images//media//premium//pre3.png"},
  //   {'image': "assets//images//media//premium//pre4.png"},
  //   {'image': "assets//images//media//premium//pre5.png"},
  //   {'image': "assets//images//media//premium//pre6.png"},
  //   {'image': "assets//images//media//premium//pre7.png"},
  //   {'image': "assets//images//media//premium//pre8.png"},
  //   {'image': "assets//images//media//premium//pre9.png"},
  //   {'image': "assets//images//media//premium//pre10.png"},
  //   {'image': "assets//images//media//premium//pre11.png"},
  //   {'image': "assets//images//media//premium//pre12.png"},
  //   {'image': "assets//images//media//premium//pre13.png"},
  //   {'image': "assets//images//media//premium//pre14.png"}
  //   // {'image': "assets//images//media//premium//pre15.png"},
  //   // {'image': "assets//images//media//premium//pre16.png"},
  //   // {'image': "assets//images//media//premium//pre17.png"},
  //   // {'image': "assets//images//media//premium//pre18.png"},
  //   // {'image': "assets//images//media//premium//pre19.png"},
  //   // {'image': "assets//images//media//premium//pre20.png"},
  //   // {'image': "assets//images//media//premium//pre21.png"},
  //   // {'image': "assets//images//media//premium//pre22.png"},
  //   // {'image': "assets//images//media//premium//pre23.png"},
  //   // {'image': "assets//images//media//premium//pre24.png"},
  //   // {'image': "assets//images//media//premium//pre25.png"},
  //   // {'image': "assets//images//media//premium//pre26.png"},
  //   // {'image': "assets//images//media//premium//pre27.png"},
  //   // {'image': "assets//images//media//premium//pre28.png"},
  //   // {'image': "assets//images//media//premium//pre29.png"},
  //   // {'image': "assets//images//media//premium//pre30.png"},
  //   // {'image': "assets//images//media//premium//pre31.png"},
  //   // {'image': "assets//images//media//premium//pre32.png"},
  //   // {'image': "assets//images//media//premium//pre33.png"},
  //   // {'image': "assets//images//media//premium//pre34.png"},
  //   // {'image': "assets//images//media//premium//pre35.png"},
  //   // {'image': "assets//images//media//premium//pre36.png"}
  // ];
  // aslides = [
    
  //   {'image': "assets//images//media//advance//adv1.png"},
  //   // {'image': "assets//images//media//advance//adv2.png"},
  //   {'image': "assets//images//media//advance//adv3.png"},
  //   {'image': "assets//images//media//advance//adv4.png"},
  //   {'image': "assets//images//media//advance//adv5.png"},
  //   {'image': "assets//images//media//advance//adv6.png"},
  //   {'image': "assets//images//media//advance//adv7.png"},
  //   {'image': "assets//images//media//advance//adv8.png"},
  //   {'image': "assets//images//media//advance//adv9.png"},
  //   {'image': "assets//images//media//advance//adv10.png"},
  //   {'image': "assets//images//media//advance//adv11.png"}
  //   // {'image': "assets//images//media//advance//adv12.png"},
  //   // {'image': "assets//images//media//advance//adv13.png"},
  //   // {'image': "assets//images//media//advance//adv14.png"}
   
  // ];
  // gslides = [
    
  //   {'image': "assets//images//media//grev//gr1.jpg"},
  //   {'image': "assets//images//media//grev//gr2.jpg"},
  //   {'image': "assets//images//media//grev//gr3.jpg"},
  //   {'image': "assets//images//media//grev//gr4.jpg"},
  //   {'image': "assets//images//media//grev//gr5.jpg"},
  //   {'image': "assets//images//media//grev//gr6.jpg"},
  //   {'image': "assets//images//media//grev//gr7.jpg"}
   
  // ];
 



  // keyword = 'name';
  // data = [
  //    {
  //      id: 1,
  //      name: 'Usa'
  //    },
  //    {
  //      id: 2,
  //      name: 'England'
  //    }
  // ];
 
 
  // selectEvent(item) {
  //   // do something with selected item
  // }
 
  // onChangeSearch(val: string) {
  //   // fetch remote data from here
  //   // And reassign the 'data' which is binded to 'data' property.
  // }
  
  // onFocused(e){
  //   // do something when input is focused
  // }
  
  ngOnInit(): void {

    // this.authenticationService.getglobalactiverfq(this.offset)
    // .pipe(first())
    // .subscribe(
    //  data => {
     
    //    this.rfqloader = false;
    //    this.activerfqall = data.message;
    //    localStorage.setItem('activerfqall', data.message);        
    //   //  this.openrfqtotal = data.total;
    //   //  this.openrfqpage = 1;
    //   //  this.openrfqper_page = 10;

    //   this.closeDate = new Date(this.activerfqall[0].submission_date);
    //   this.closeMonth = this.closeDate.getMonth();
    //   console.log(this.closeDate);
         
    //    },
    //    error => {
           
          
    //    });


       
//        $scope.myDate = new Date("2016-11-19 00:00:00");


// $scope.myDate_month = $scope.myDate.getMonth();
// $scope.myDate_day = $scope.myDate.getDate();
// $scope.myDate_year = $scope.myDate.getFullYear();
// $scope.time = $scope.myDate.getHours();
// $scope.time = $scope.myDate.getMinutes();


  }

  // sendmessage(){
   
  //   // console.log("Subject-" + this.subject);
  //   // console.log("NAME-" + this.name);
  //   // console.log("Email" + this.email);
  //   // console.log("Phone" + this.phone);
  //   // console.log("Message" + this.message);

  //    if((this.name=="")||(this.subject=="")||(this.email=="")||(this.phone=="")||(this.message=="")) {

  //       this.toastr.errorToastr("All fields are required!");
  //       return false;

  //    }
     
  //   Swal.fire('Thank you for contacting us , Our Team will get back to you as soon as possible','','success');
   
  //   this.authenticationService.sendmessage(this.name, this.email, this.subject, this.message, this.phone)
  //   .pipe(first())
  //   .subscribe(
  //   data => {
  //   //  this.toastr.successToastr('Thank you for your contacting us. Our team will respond you within 24hrs ', 'Success!');
  //   });
  // }
  
}
