<div>
    <login-home-navigation></login-home-navigation>
	<p>&nbsp;</p>
</div>


<section class="sptb innerpage-margin" style="background-color:#fff;" >
    <div class="container">
      <div class="card">
        <a href="/vendors-benefits" ><img src="../../../assets/images/media/newyear.webp" width="100%" height="100%" ></a>
        
    </div>
     </div>       

</section>

<section class="sptb.sec2 innerpage-margin " style="background-color:#fff;" >
<div class="container">
    <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-7">
    <!-- <div class="card"> -->
         <!-- <img src="../../../assets/images/media/home/category/all/cats.gif" width="100%" height="80%">  -->
        <a href="login/signup" > <video loop width="100%" height="100%"  controls autoplay muted>
            <source src="../../../assets/images/media/section2.mp4" type="video/mp4">
         </video></a>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-7 section2-body">
            <p>Looking for the perfect platform to grow your manufacturing business? Look no further than GetYourVendor!</p><p> Our state-of-the-art features allow you to reach out to global OEMs, boosting your revenue and taking your business to the next level. 
                With the latest market trends and an effective algorithm, we help manufacturers like you find new business opportunities and stay ahead of the competition.</p> <p>With our platform, you can seize your next business opportunity right from your desk, saving a minimum of 20% of your sales and marketing budget.</p>
                <p style="align-items: center;margin-top: 2%"><a href="login/demo-signup" ><button class="ovalbtn"><b>BOOK LIVE DEMO</b></button></a> </p>
        </div>
    </div>
      
</div>
        
</section>
<section class="sptb innerpage-margin " style="background-color:#fff" >

    <div class="container">
    <div class="row">
       
        <div class="col-lg-6 col-md-6 col-sm-6 section2-body">
            <p>We are delighted to have effectively assisted customers across India and internationally in connecting with their ideal buyers. Additionally, we are pleased to have secured orders of various sizes with a success rate of 99%.</p>
            <p><img src="assets/images/media/home/flow.webp" title="vendor process"></p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
            <a href="/vendors-benefits"> <img src="assets/images/media/home/map.webp" title="wins" alt="wins" width="auto" height="auto"></a>
            
        </div>
        
       

    </div>
    </div>

</section>
    
<section class="sptb innerpage-margin " style="background-color:#fff">
    <div class="container">
        <div>
         <a href="/newpricingpage"><img src="assets/images/media/home/land_banner.webp" title="special offer" alt="offer" width="auto" height="auto"></a>
        </div>
        
    </div>

</section>




<section class="sptb innerpage-margin " style="background-color:#fff" >

    <div class="container">
       
        <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-5 section2-body">
            
           
            <p> At GetYourVendor, we understand that custom manufacturing is key to a strong economy, which is why we're committed to delivering the best services possible. And with our platform, Indian and global manufacturers can connect with each other in the most economical way possible, increasing their profit margins and driving success.</p><p> Don't wait - sign up for GetYourVendor now and take the first step towards a brighter future!

            </p>
            <p style="align-items: center;margin-top: 2%"><a href="login/demo-signup" ><button class="ovalbtn"><b>BOOK LIVE DEMO</b></button></a> </p>
        </div>
       
        <div class="col-lg-7 col-md-7 col-sm-7 ">
           <a href="/buyers-benefits"> <img src="assets/images/media/home/buyer2.webp" title="buyers benefits" alt="buyers benefits" width="100%" height="auto"></a>
          
        </div>
       
    </div> 
    </div>
    <div class="container" style="padding-top: 3%;" >
                
        <h2>&#x275D;More than 2500 RFQ processed by platform &#x275E;</h2>
    </div>
    </section>
    <section class="sptb innerpage-margin " style="background-color:#fff">
        <div class="container">
            <img src="assets/images/media/home/stats.webp" title="stats" alt="stats" width="auto" height="auto">
        </div>

    </section>



   
    <section class="sptb innerpage-margin sec5" style="background-color:#fff;" >
        <!-- <div class="row"> -->
     <div class="container">
         <div class="row">
       
        <div class="col-md-6 col-sm-6 " >
         <a href="/vendors-benefits"><img src="assets/images/media/home/vendorsection.webp" alt="vendor benefits" width="100%" height="auto" ></a>
         <!-- <p style="align-items: center;"><a href="login/signup1" ><button class="btn-reg">Send Quote Now</button></a> </p> -->
        </div>
        <div class="col-md-6 col-sm-6 section2-body">
            <h2 style="text-align: left;padding-bottom: 3%;">VENDOR'S BENEFIT'S</h2>
            <p>&#10004;&nbsp; Gain unlimited RFQ access without any Vendor Code.</p>
            <p>&#10004;&nbsp; Show up on the radar of global Buyers without the need for middlemen.</p>
            <p>&#10004;&nbsp; Enjoy equal opportunities, and get a free Vendor assessment report.</p>
            
            <p><a href="/vendors-benefits" style="color: red">Find More Benefits..</a></p>
            <p style="align-items: center;"><a href="login/signup" ><button class="ovalbtn">Start Your Free Trial Now</button></a> </p>
        </div>
        <!-- <div class="col-md-2 col-sm-2">
            <p class=""><a href="/signup" ><button class="btn-reg">Send Quote Now</button></a> </p>
        </div> -->
    </div>
</div>
</section>

<section class="sptb innerpage-margin" style="background-color:#fff">
    <div class-="container">
        <h2 style="color: rgb(0, 0, 0);">Additional Benefits</h2> 
    </div>
    
</section>

    <section class="sptb innerpage-margin sec7" style="background-color: #fff;">
     
    <div class="container">
        <div class="row  why">
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black;align-content: center;">
                <p><i class="fa fa-briefcase fa-5x" style="color: red; font-size: 36px; "></i></p><p style="text-transform: uppercase;  color: black;">Save 20-25% Marketing Cost</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><i class="fa fa-certificate fa-5x" style="color: red;font-size: 36px;"></i></p><p style="text-transform: uppercase;color: black;"> Get Free Vendor Assessment Report </p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><i class="fa fa-user fa-5x" style="color: red;font-size: 36px;"></i></p><p style="text-transform: uppercase;color: black;">Get New Vendor Code</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><i class="fa fa-smile-o fa-5x" style="color: red; font-size: 36px;"></i></p><p style="text-transform: uppercase; color: black;">Priority Packages</p>
            </div>

        </div>
        <div class="row why" style="padding-top: 0%;">
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><i class="fa fa-rupee fa-5x" style="color: red; font-size: 36px; "></i></p><p style="text-transform: uppercase;  color: black;">Economical Subscription </p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><i class="fa fa-phone-square fa-5x" style="color: red;font-size: 36px;"></i></p><p style="text-transform: uppercase;color: black;">Technical Expert Consultation</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><i class="fa fa-globe fa-5x" style="color: red;font-size: 36px;"></i></p><p style="text-transform: uppercase; color: black;">Global Platform</p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3" style="border: thin solid black; align-content: center;">
                <p><i class="fa fa-database fa-5x" style="color: red; font-size: 36px;"></i></p><p style="text-transform: uppercase; color: black;">Data Security</p>
            </div>

        </div>


   </div>

    </section>
	

    <section class="sptb innerpage-margin sec8" style="background-color: #fff">

        <div class="container">
            <div style="text-align: center;">
                    <h2>CUSTOMER TESTIMONIAL </h2>
                    
                </div>
        </div>     
        <div class="wrapper">
            <div class="box">
            <i class="fa fa-quote-left quote"></i>
            <p>Your dedication and support have been instrumental in enabling us to secure new business opportunities worth 25 lakhs per month. We are grateful for your platform and the opportunities it has provided us. Your efforts inspire us to strive for greater success and we look forward to exploring more opportunities through <a href="">Getyourvendor.com </a>
                </p>
            <div class="content">
                <div class="info">
                <div class="name">Mr.Jagannath Ramdasi</div>
                <div class="job">Business Development Manager</div>
                <div class="job">Kirdak Autocom Pvt Ltd</div>
                <div class="stars">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                </div>
                </div>
                <div class="image">
                <img src="assets/images/media/home/review/ram.webp" alt="ram" width="100%" height="100%">
                </div>
            </div>
            </div>
            <div class="box">
            <i class="fa fa-quote-left quote"></i>
            <p>I am happy to register my company on <a href="">Getyourvendor.com </a> is a game-changer for suppliers. By registering my company on this platform, I was able to connect with hundreds of buyers from all over India. Thanks to their alert feature, I received a bulk order from a buyer within a month of registering. I couldn't be happier with my experience on GetYourVendor.com and strongly recommend it to all my fellow suppliers. Don't miss out on this opportunity to expand your business and grow your network.</p>
            <div class="content">
                <div class="info">
                <div class="name">Mr. Arvind Shete</div>
                <div class="job">Owner|MNS Engineers</div>
                <div class="stars">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                </div>
                </div>
                <div class="image">
                <img src="assets/images/media/home/review/arvind.webp" alt="arvid" width="100%" height="100%">
                </div>
            </div>
            </div>
            <div class="box">
            <i class="fa fa-quote-left  quote"></i>
            <p> We are thrilled to share some wonderful news with you. We have received our very first order through your portal. We deeply appreciate all the hard work and effort that the GYV team has put in. Your team has been helpful and professional from day one and we sincerely thank you for your support. The GYV portal has a lot of relevant RFQs from genuine buyers which is very helpful. We are excited about the possibility of establishing a long-term business relationship with you. 
            </p>
            <div class="content">
                <div class="info">
                <div class="name">Mr.Ankit Patel </div>
                <div class="job">Owner|Dimension Enterprise</div>
                <div class="stars">
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                    <i class="fa fa-star"></i>
                </div>
                </div>
                <div class="image">
                <img src="assets/images/media/home/review/ankitp.webp" alt="ankit" width="100%" height="100%">
                </div>
            </div>
            </div>
            <div class="container " style="padding-top:1%;">
                <p style="text-align: right; text-transform: uppercase;"><a href="/testimonials"><b>View More Testimonials <i class="fa fa-hand-o-right fa-lg" style="color: orange;"></i></b></a></p>
                
            </div>
        </div>
        

    </section>
    <section class="innerpage-margin faq" style="background-color: #fff;">
        <div class="container">
        <h2 style="margin-bottom:2%"> Frequently Asked Questions?</h2>
            <div aria-multiselectable="true" class="accordion1" id="accordion" role="tablist">
                 <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse1">
                        1. Who qualifies to become a vendor or supplier?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse1" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;" >
                            Companies that engage in custom-manufacturing activities and are registered under the government-authorized industry laws of their respective countries are eligible to become vendors or suppliers.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse2">
                        2. Who qualifies to become a buyer?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse2" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Any company interested in outsourcing manufacturing activities may become a buyer.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse3">
                        3. What are the charges associated with this platform?
                    </div>
                    <div  class="collapse" data-parent="#accordion" id="collapse3" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            The subscription packages for this platform start at approximately 60 INR per day. For more information on the different packages available, checkout <a href="./newpricingpage"target="_blank" >Vendor Packages</a>.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse4">
                        4.  What assurance does the platform offer for business success?
                    </div>
                    <div  class="collapse" data-parent="#accordion" id="collapse4" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our short-term subscriber conversion rate is around 50%, while our long-term subscriber conversion rate is an impressive 99%. Additionally, our tailored packages come with a money-back guarantee.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse5">
                        5. How often do new businesses (RFQ) appear on the platform?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse5" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our database is updated with fresh business opportunities every Tuesday and Friday of each week.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse6">
                        6. How many registrations are allowed under a single company name?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse6" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our Gold package allows for multiple registrations under one company name. Users can be added to a package by paying nominal user fees.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse7">
                       7. How do I contact the buyer for further communication once a new RFQ is found?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse7" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our technology-driven platform assists you in securing orders once you identify a suitable RFQ.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse8">
                        8. Is my data secure on the platform?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse8" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Your data, including drawings, quotations, and profile information, is safeguarded by our advanced security measures and digital non-disclosure agreement features.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse9">
                        9. What types of buyers are using the platform?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse9" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our buyers come from a variety of sectors including Automotive, Engineering, Aerospace, Shipbuilding, Machine Manufacturing, Oil and Gas, Construction, Railways, Chemicals, Electronics, Electrical, 3D Printing, and Pharmaceutical industries.
                        </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse10">
                        10. How can I register my company on the platform?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse10" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Click on the <a href="/login/signup"> link </a> to initiate a lasting partnership with GetYourVendor.
                        </div>
                    </div>
                </div>
                
            </div><!-- accordion -->
        </div>
    </section>
    <login-home-footer></login-home-footer>