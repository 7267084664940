
<div class="banner-1 cover-image bg-background1" data-image-src="assets/images/banners/banner4.jpg">
    <login-home-navigation></login-home-navigation>
    <!-- <p>&nbsp;</p> -->
</div>


<section class="sptb innerpage-margin bg-white ">
    <div class="container">
        <div class="row"  >
            <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top:9%;">
                <div class="card-mission ">
                
                    <h1>&#x275D; We are dedicated to building a healthy ecosystem for custom manufacturing in India where buyers and vendors can easily communicate and create mutually beneficial partnerships. We aim to remove barriers to entry and foster an environment of collaboration, innovation, and growth. &#x275E;</h1>
                
               </div>
            </div>
        </div>
        
        
    
    </div>
              
   

</section>
         

<section class="sptb innerpage-margin" style="background-color:white ;" >
    <div class="container">

        <div class="row">
        
            <div class="col-lg-12 col-sm-12 col-md-12">
                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h2 style="text-align:center"><b>OVERVIEW</b></h2>
								
								
								<img src="assets/images/media/sourcing.webp" width="50%" height="50%" alt="sourcing">
                       <div>
                        <p>Foreign corporations, both big and small, are diversifying their global supply chains, and India has emerged as a favorable destination for finding manufacturing partners or subcontractors. The Indian government has been promoting schemes, such as Make in India, to attract foreign companies. As a result, many companies are now outsourcing their manufacturing to local Indian vendors, who can produce essential components for their final products. </p>
                        <p>
                          However, the question arises as to how foreign buyers and Indian B2B manufacturers can connect with each other to discuss the prospects of building long-term partnerships to ensure the supply of high-quality components.
                          </p>
                          <p>To address this issue, our technology-driven platform, 'Get Your Vendor,' has been designed to help businesses focus on revenue-generating aspects. It is a user-friendly platform where registered vendors and buyers can connect, collaborate, and build a strong network. We also offer domain expertise to guide you through the sourcing process.</p>
                          <p>Our passionate team, led by Mr. Shekhar Erande (Founder & CEO), strives to provide end-to-end solutions in the custom manufacturing business and help vendors and buyers discover each other for a paradigm shift in the sourcing process.</p>
                       </div>       
                               
                        </div>
                            
                           
						</div>
                    </div>
                   
                </div>
               
            </div>
            <!--Right Side Content-->
         
            <!--/Right Side Content-->
        </div>
    </div>
</section><!--/Section-->

<section class="sptb" style="background-color: #fff;">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <h2>CORE VALUES</h2>
        <img src="assets/images/media/corevalues.png" title="corevalues">
      </div>
      
        
      <div class="col-lg-12 col-md-12 col-sm-12 sptb blog-detail">
        <h2>VISION</h2>
        <p>"Our vision is to establish a world-class sourcing platform that meets the needs of businesses across the globe."</p>
       
      </div>
    </div>
  </div>

</section>

  <section class="sptb " style="background-color: #fff;">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 card">
       
          <p><img src="assets/images/media/flags.webp" width="100%" height="100%"></p>
          
        
        </div>
      </div>
      </div>
    
  </section>
  <section class="sptb" style="background-color: #fff;">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <img src="assets/images/media/isspl.webp" width="100%" height="90%" >
        </div>
      </div>
    </div>

  </section>
  <section class="sptb" style="background-color: #fff;">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 blog-detail">
          <p><li><a routerLink = "../blog/How-to-Find-Reliable-Indian-Manufacturing-Vendors">How to Find Reliable Indian Manufacturing Vendors</a></li></p>
          <p><li><a routerLink = "../blog/Custom-Manufacturing-The-USP-of-distinctive-brands">Custom Manufacturing The USP of distinctive brands</a></li></p>
          <p><li><a routerLink = "../blog/Vendor-Development-strategic-sourcing">Vendor Development Strategic Sourcing</a></li></p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 blog-detail">
          <p><li><a routerLink = "../blog/4-Tips-To-Optimize-Your-Supply-Chain-Cost">4 Tips To Optimize Your Supply Chain Cost</a></li></p>
          <p><li><a routerLink = "../blog/Procurement-Management-System">Procurement Management System</a></li></p>
          <p><li><a routerLink = "../blog/The-Manufacturing-Industry-Behind-the-Scenes">The Manufacturing Industry Behind the Scenes</a></li></p>
        </div>
      </div>
    </div>
  
  </section>
  <section class="sptb" style="background-color: #fff;" >
    <div class="container"> 
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/iBufZP4N8Vw" title="Customer-Siddharth" frameborder="0" loading="lazy" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/ZOnQMfWubFY" title="Customer-Kirdak" frameborder="0" loading="lazy" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
  </div>
  </section>

  <section class="sptb" style="background-color: #fff;" >
    <div class="container"> 
      <div class="row" style="filter: grayscale(100%);">
        <marquee  behavior="scroll" direction="left">        
          <img src=
          "assets/images/media/buyer1.webp"
          alt="buyer1"> 
        </marquee>  
        <marquee  behavior="scroll" direction="right">        
          <img src=
          "assets/images/media/buyer2.webp"
          alt="buyer1"> 
        </marquee>  
        <marquee  behavior="scroll" direction="left">        
          <img src=
          "assets/images/media/buyer3.webp"
          alt="buyer1"> 
        </marquee>  
      </div>
  </div>
  </section>
<section class="sptb" style="background-color: #fff;" >
  <div class="container"> 
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <a href="login/signup" > <img src=
          "assets/images/media/vendorbtn.webp"
          alt="vendor"> </a>
        
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6">
        <a href="login/signup" ><img src=
        "assets/images/media/buyerbtn.webp"
        alt="buyer"> </a>
      </div>
    </div>

  </div>

</section>


<login-home-footer></login-home-footer>

