
    <login-home-navigation></login-home-navigation>
            

	<section class="sptb innerpage-margin" style="background-color:#fff;padding-top: 10%;">
		<div class="container">
	
					<!--Itemsd lists-->
					<div class="row">
						<div class="col-lg-8 col-md-8 col-sm-6">
							<div class="card blog-detail">
								<div class="card-body">
									<h2><a routerLink = "Why-Procurement-Needs-To-Look-Beyond-The-Traditional-Methods"  class="text-dark">Why Procurement Needs To Look Beyond The Traditional Methods?
									</a></h2>
									<div class="item7-card-desc d-flex mb-5">
										<a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i>Nov-07-2023</a>
										
										
									</div>
									
									<img src="assets/images/media/procurement.webp" alt="procurement" width="30%" height="30%" title="procurement">
									<p>In today's rapidly evolving business landscape, the procurement function plays a pivotal role in driving organizational success. 
										</p>
									<p>While traditional procurement methods have served organizations well in the past, the dynamic and complex nature of modern supply chains demands a shift towards innovative and forward-thinking approaches. 
 
									</p>
									<!-- <a (click)="scroll(blog_bts)" class="btn btn-info btn-md ml-2 text-white">READ MORE</a> -->
									<p><a routerLink = "Why-Procurement-Needs-To-Look-Beyond-The-Traditional-Methods" class="btn btn-info btn-md ml-2 text-white" >Read More..</a></p>
								</div>
							</div>
						</div>
						<div class="col-lg-4 col-md-4 col-sm-6">
							<p><li><a routerLink = "How-to-Find-Reliable-Indian-Manufacturing-Vendors"  >How to Find Reliable Indian Manufacturing Vendors</a></li></p>
							<p><li><a routerLink = "Custom-Manufacturing-The-USP-of-distinctive-brands"  >Custom Manufacturing-The USP of distinctive brands</a></li></p>
							<p><li><a routerLink = "Vendor-Development-strategic-sourcing"  >Vendor Development strategic sourcing</a></li></p>
							<p><li><a routerLink = "First-Year-as-a-founder">First Year as a founder</a></li></p>
							<p><li><a routerLink = "4-Tips-To-Optimize-Your-Supply-Chain-Cost">4 Tips To Optimize Your Supply Chain Cost</a></li></p>
							<p><li><a routerLink = "Budget-2021-What-does-it-have-in-store-for-the-Manufacturing-Industry">Budget 2021-What does it have in store for the Manufacturing Industry</a></li></p>
							<p><li><a routerLink = "Procurement-Management-System">Get Your Vendor: The Purpose, Features & Benefits</a></li></p>
							<p><li><a routerLink = "The-Manufacturing-Industry-Behind-the-Scenes">The Manufacturing Industry: Behind the Scenes</a></li></p>
						</div>
					   
					</div>
		</div>
		
	   
	</section><!--/Section-->
	
<section class="sptb innerpage-margin" style="background-color:#fff;padding-top: 4%;">
    <div class="container">

                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h2><a routerLink = "How-to-Find-Reliable-Indian-Manufacturing-Vendors"  class="text-dark">How to Find Reliable Indian Manufacturing Vendors</a></h2>
								<div class="item7-card-desc d-flex mb-5">
									<a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i>Jan-01-2023</a>
									
									
								</div>
								
								<img src="assets/images/media/reliablemfg.webp" alt="reliable" width="30%" height="30%" title="manufacturing">
								<p>With global supply chains diversifying to other nations, several big and small foreign corporations are now discovering India as a favorable destination to find their manufacturing partners or subcontractors. The government of India has been promoting schemes such as Make in India to attract foreign companies. 
                                    </p>
								<p>As a result of favorable policies towards foreign companies, several companies are now striving for manufacturing outsourcing to local Indian vendors to whom they can delegate the production of some of the essential components of their final product. 
                                </p>
								<!-- <a (click)="scroll(blog_bts)" class="btn btn-info btn-md ml-2 text-white">READ MORE</a> -->
								<p><a routerLink = "How-to-Find-Reliable-Indian-Manufacturing-Vendors" class="btn btn-info btn-md ml-2 text-white" >Read More..</a></p>
							</div>
						</div>
                    </div>
					
                   
                </div>
    </div>
    
   
</section><!--/Section-->




<!--Section-->
<section class="sptb innerpage-margin" style="background-color: #fff">
    <div class="container">

       
                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h2><a routerLink = "Custom-Manufacturing-The-USP-of-distinctive-brands"  class="text-dark">Custom Manufacturing: The USP of distinctive brands</a></h2>
								<div class="item7-card-desc d-flex mb-5">
									<a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i>Oct-18-2021</a>
									
									
								</div>
								<div>
								<img src="assets/images/media/custom_manufacturing.webp" alt="custom_manufacturing" width="30%" height="30%">
								<p>The manufacturing or vendor can make or break a business – find a custom manufacturer to help you stand out in a crowd!</p>
								<p><strong>What is Custom Manufacturing?</strong></p>
								<p>Have you ever seen a jewellery item that isn’t like the rest? Or a medical solution that suits a specific patient or purpose? That could be seen as a custom-made or custom-manufactured product.
									What we see as ‘bespoke’ or ‘customized’ nowadays is simply the process of designing, engineering and producing goods based on a customer’s unique specifications. This includes one-offs, build-to-order or made-to-order parts, short production runs as well as mass customization.
									Choose your vendor from our revolutionary, end-to-end solutions at <a href="https://www.getyourvendor.com">Get Your Vendor</a></p>
								<!-- <a (click)="scroll(blog_cm)" class="btn btn-info btn-md ml-2 text-white">READ MORE</a> -->
								<a routerLink = "Custom-Manufacturing-The-USP-of-distinctive-brands"  class="btn btn-info btn-md ml-2 text-white" >Read More..</a>
								</div>
							</div>
						</div>
                    </div>
                   
                </div>
               
        
          
        </div>
</section><!--/Section-->

<section class="sptb innerpage-margin" style="background-color: #fff">
    <div class="container">

        <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h2><a routerLink = "Vendor-Development-strategic-sourcing"  class="text-dark">Vendor Development: Strategic Sourcing</a></h2>
								<div class="item7-card-desc d-flex mb-5">
									<a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i>Oct-18-2021</a>
									
									
								</div>
								<div>
								<img src="assets/images/media/strategic_sourcing.webp" alt="strategic sourcing" width="30%" height="30%" title="sourcing">
								<p>Keeping yourself up-to-date with the market trends, shifting consumer demand, new business models and technological advancements is all a part of strategic sourcing for vendor development.</p>
								<p><strong>What is Vendor Development?</strong></p>
								<p>Developing your product or parts of it or the unfinished products from your vendor is what vendor development essentially is. This, in turn, improves your business in the aspects of finance, scalable growth, manufacturing, and the supply chain.</p>
								<!-- <a (click)="scroll(blog_vd)" class="btn btn-info btn-md ml-2 text-white">READ MORE</a> -->
								<a routerLink = "Vendor-Development-strategic-sourcing"  class="btn btn-info btn-md ml-2 text-white" >Read More..</a>
							</div>
							</div>
						</div>
                    </div>
                   
                </div>
               
        
        </div>

</section><!--/Section-->

<section class="sptb innerpage-margin" style="background-color: #fff">
    <div class="container">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h2 class="fs-24"><a routerLink = "First-Year-as-a-founder"  class="text-dark">First Year as a co-founder !!</a></h2>
								<div class="item7-card-desc d-flex mb-5">
									<a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i>AUGUST 21, 2021</a>
									
									
								</div>
								<div>
								<img src="assets/images/media/firstyear.webp" alt="1styear" width="30%" height="30%" title="Co-Founder">
								<p>A year ago we launched our bootstrapped platform GetYourVendor for Indian custom manufacturing companies. It feels just like yesterday when I was writing our first specification document and the first line of code.</p>
								<!-- <a (click)="scroll(blog_fy)" class="btn btn-info btn-md ml-2 text-white">READ MORE</a> -->
								<a routerLink = "First-Year-as-a-founder"  class="btn btn-info btn-md ml-2 text-white" >Read More..</a>
							</div>
							</div>
						</div>
                    </div>
                   
                </div>
               
                <!--/Itemsd lists-->
        
    </div>
</section><!--/Section-->

<section class="sptb innerpage-margin" style="background-color: #fff">
    <div class="container">

        <div class="row">
        
            <div class="col-xl-12 col-lg-12 col-md-12">
                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h2 class="fs-24"><a routerLink = "4-Tips-To-Optimize-Your-Supply-Chain-Cost"  class="text-dark">4 Tips To Optimize Your Supply Chain Cost</a></h2>
								<div class="item7-card-desc d-flex mb-5">
									<a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i>JULY 14, 2021</a>
									
									
								</div>
								<div>
								<img src="assets/images/media/supplychain.webp" alt="supplychain" width="30%" height="30%">
								<p>Cost minimization in supply chain management is one of the main KPIs for every manager in a business. It constitutes a huge danger to the finances and growth of any company if done incorrectly.</p>
								<!-- <a (click)="scroll(blog_tips)" class="btn btn-info btn-md ml-2 text-white">READ MORE</a> -->
								<a routerLink = "4-Tips-To-Optimize-Your-Supply-Chain-Cost" class="btn btn-info btn-md ml-2 text-white" >Read More..</a>
							   </div>
							</div>
						</div>
                    </div>
                   
                </div>
               
       
            </div>
       
        </div>
    </div>
</section><!--/Section-->

<section class="sptb innerpage-margin" style="background-color: #fff">
    <div class="container">

       
                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h2 class="fs-24"><a routerLink = "Budget-2021-What-does-it-have-in-store-for-the-Manufacturing-Industry"  class="text-dark">Budget-2021: What does it have in store for the Manufacturing Industry?</a></h2>
								<div class="item7-card-desc d-flex mb-5">
									<a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i> FEBRUARY 24, 2021</a>
									
									
								</div>
								<div>
								<img src="assets/images/media/Budget_2021.webp" alt="img" width="30%" height="30%" title="Budget">
								<p> The wave of COVID-19 carved a massive dent in the growth of multiple industries - the Manufacturing industry being one of the most affected ones. The series of lockdowns resulted in a sudden stop on...</p>
								<!-- <a (click)="scroll(blog_budget)" class="btn btn-info btn-md ml-2 text-white">READ MORE</a> -->
								<a routerLink = "Budget-2021-What-does-it-have-in-store-for-the-Manufacturing-Industry"  class="btn btn-info btn-md ml-2 text-white" >Read More..</a>
							    </div>
							</div>
						</div>
                    </div>
                   
                </div>
               
           
            </div>
           
      
</section><!--/Section-->


<section class="sptb innerpage-margin" style="background-color: #fff">
    <div class="container">

       
                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h2 class="fs-24"><a routerLink = "Procurement-Management-System"  class="text-dark">Get Your Vendor: The Purpose, Features & Benefits</a></h2>
								<div class="item7-card-desc d-flex mb-5">
									<a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i>JANUARY 4, 2021</a>
									
									
								</div>
								<div>
								<img src="assets/images/media/gyvpurpose.webp" alt="getyourvendor" width="30%" height="30%" title="gyvpurpose">
								<p> Technological development has taken the Manufacturing industry to newer heights but some challenges remain. Even today, the majority of vendors are sourced through references & networking. It works, but has its limitations - like the number of references...</p>
								<!-- <a (click)="scroll(blog_gyv)" class="btn btn-info btn-md ml-2 text-white">READ MORE</a> -->
								<a routerLink = "Procurement-Management-System"  class="btn btn-info btn-md ml-2 text-white" >Read More..</a>
								</div>
							</div>
						</div>
                    </div>
                   
                </div>
      
            </div>
      
</section><!--/Section-->

<section class="sptb innerpage-margin" style="background-color: #fff">
    <div class="container">

   
                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h2 class="fs-24"><a routerLink = "The-Manufacturing-Industry-Behind-the-Scenes"  class="text-dark">The Manufacturing Industry: Behind the Scenes</a></h2>
								<div class="item7-card-desc d-flex mb-5">
									<a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i>Nov-28-2020</a>
									
									
								</div>
								
								<img src="assets/images/media/blog1.webp" alt="img" width="420" title="behindscene">
								<p>The first half of 2020 brought a wave of COVID-19. The pandemic soon spread like wildfire, bringing the largest 
									of industries to a standstill. But, thanks to the lockdown strategies implemented by the government,
									 the efforts of frontline Corona Warriors, and the cooperation of every citizen, industries are slowly standing
									 back on their feet; same is the case with the Manufacturing industry. Regardless, some challenges still prevail.</p>
								<p>To devise 360-degree solutions, it’s important to first understand the problem! Let’s study the challenges faced by the ever-evolving Manufacturing industry and then move on to the solution- </p>
								<!-- <a (click)="scroll(blog_bts)" class="btn btn-info btn-md ml-2 text-white">READ MORE</a> -->
								<a routerLink = "The-Manufacturing-Industry-Behind-the-Scenes"  class="btn btn-info btn-md ml-2 text-white" >Read More..</a>
							</div>
						</div>
                    </div>
                   
                </div>
               
       
            </div>
       
         
       
</section><!--/Section-->

<section class="sptb" style="background-color: #fff;" >
	<div class="container"> 
	  <div class="row">
		<div class="col-lg-6 col-md-6 col-sm-6">
		  <a href="login/signup" > <img src=
			"assets/images/media/vendorbtn.webp"
			alt="vendor"> </a>

		</div>
		<div class="col-lg-6 col-md-6 col-sm-6">
		  <a href="login/signup" ><img src=
		  "assets/images/media/buyerbtn.webp"
		  alt="buyer"> </a>
		</div>
	  </div>
  
	</div>
  
  </section>





<login-home-footer></login-home-footer>